import BootstrapTable from "react-bootstrap-table-next";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  Input,
} from "../../../_metronic/_partials/controls";
import { CardActions } from "@material-ui/core";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { useFetchProductCategories } from "../hooks/useFetchProductCategoriesData";
import { Spinner, Alert } from "react-bootstrap";
import { IProductCategory } from "../models/ProductGroups";
import BoxIcon from "../svg/BoxIcon";
import TubeIcon from "../svg/TubeIcon";
import EditIcon from "../svg/EditIcon";
import GearIcon from "../svg/GearIcon";
import { useHistory } from "react-router-dom";
import { usePlant } from "../../components/PlantHook";
import isEmpty from "../../utils/isEmpty";
import { useEffect, useState } from "react";
import ProductCategoryAddDialog from "../components/productCategory/ProductCategoryAddDialog";
import { getBundleTypesLookup } from "../../packaging/api/PackagingApi";
import * as actions from "../redux/ProductGroupActions";
import { shallowEqual, useDispatch, useSelector} from "react-redux";
import ImportFromFile from "../../components/ImportFromFile";
import { Field } from "formik";

interface IProductGroup {
  productGroup: string;
  productType: string;
  shape: string;
  consolidationType: string;
  consolidationTypeText: string;
  bundleWith: string;
}

const ProductGroupsPage = (props: any) => {

  const dispatch = useDispatch();

  const { alert, apiCallInProgress } = useSelector(
    (state: any) => ({
      alert: state.productGroup?.alert || null,
      apiCallInProgress: state.productGroup?.apiCallInProgress || false
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(actions.dismissAlert());
  }, []);

  const plant = usePlant();

  const history = useHistory();

  const [isAddDialogOpen, setAddDialogOpen] = useState(false);

  const [isEditDialogOpen, setEditDialogOpen] = useState(false);

  const [selectedProductGroup, setSelectedProductGroup] = useState<any>(null);

  const { isLoading, productCategories, error, refetchProductCategories } =
    useFetchProductCategories(plant);

  const [consolidationTypes, setConsolidationTypes] = useState<Array<{ key: string; description: string }>>([]);

  const [productGroupNameToImport, setProductGroupNameToImport] = useState("");

  function getConsolidationTypeDisplayName(input: string): string {
    return consolidationTypes.find(b => b.key === input)?.description || "";
  }

  useEffect(() => {
    if (consolidationTypes.length === 0) {
      getBundleTypesLookup().then(setConsolidationTypes).catch(error => console.error('Failed to load consolidation types:', error));
    }
  })

  const handleProductGroupClick = (productGroup: string) => {
    history.push(`/products/${productGroup}`);
  };

  const handleEditClick = (singleProductGroup: any) => {
    setSelectedProductGroup(singleProductGroup);
    setEditDialogOpen(true);
  };

  const handleDismissAlert = () => {
    dispatch(actions.dismissAlert());
  };

  const handleExportClick = async (singleProductGroup: any) => {
    await dispatch(actions.exportProductGroup(plant, singleProductGroup.productGroup));
  };

  const handleCancelClick = () => {
    setAddDialogOpen(false);
    setEditDialogOpen(false);
  };

  const handleImport = async (file: File, dryRun: boolean): Promise<{ success: boolean, messages: string[] }> => {
    if (productGroupNameToImport === '') {
      return { success: false, messages: ["Please, enter a group name."] };
    }
    const response = await actions.importProductGroup(plant, productGroupNameToImport, file, dryRun);
    if (response !== undefined) {
      if (!dryRun) {
        refetchProductCategories();
      }
      return { success: true, messages: [`Number of Articles ${response.articlesCount}. Number of Modifiers ${response.productModifiersCount}`] };
    } else {
      return { success: false, messages: ["An error occurred"] };
    }
  }

  const handleFormSubmitSuccess = async () => {
    try {
      // Call the refetch function to update the packaging group data
      refetchProductCategories();
    } catch (error) {
      console.error(
        "Error in handleFormSubmitSuccess on ProductGroupsPage.tsx :",
        error
      );
    }
  };

  const productGroupNameUniquenessCheck = (newProductGroupName: string): boolean => {
    return !productCategories?.some(pg => pg.name === newProductGroupName);
  };

  const productGroupData =
    productCategories?.map(
      (category: IProductCategory): IProductGroup => ({
        productGroup: category.name,
        productType: category.productType,
        shape: category.defaultShape,
        consolidationType: category.bundleType,
        consolidationTypeText: getConsolidationTypeDisplayName(category.bundleType),
        bundleWith: category?.productCategoriesToMix?.join(", "),
      })
    ) ?? [];

  const columns = [
    {
      dataField: "productGroup",
      text: "Name",
      classes: "text-left",
      headerClasses: "text-center",
    },
    {
      dataField: "shape",
      text: "Default shape",
      classes: "text-center",
      headerClasses: "text-center",
      formatter: (cell: unknown, row: { shape: string }) => {
        if (row.shape.toLowerCase() === "cuboid") {
          return <BoxIcon />;
        }
        if (row.shape.toLowerCase() === "roll") {
          return <TubeIcon />;
        }
        return row.shape;
      },
    },
    {
      dataField: "productType",
      text: "Product Type",
      classes: "text-left",
      headerClasses: "text-center",
    },
    {
      dataField: "consolidationTypeText",
      text: "Consolidation Type",
      headerClasses: "text-center",
      classes: "text-center"
    },
    // {
    //   dataField: "bundleWith",
    //   text: "Consolidate With",
    //   headerClasses: "text-center",
    //   classes: "text-center",
    // },
    {
      dataField: "action",
      text: "Action",
      formatter: (cell: any, row: any) => (
        <>
          <button
            type="button"
            className="btn btn-link"
            title="Edit"
            onClick={() => handleEditClick(row)}
          >
            <EditIcon />
          </button>
          <Link to={`/products/${row.productGroup}`} onClick={(e) => e.stopPropagation()} title="Configure">
            <GearIcon />
          </Link>
          <button
            type="button"
            className="btn btn-link"
            title="Export"
            onClick={() => handleExportClick(row)}
          >
            <SVG src={toAbsoluteUrl("/media/svg/icons/Files/Export.svg")} className="svg-icon-md" />
          </button>
        </>
      ),
      classes: "text-right pr-15",
      headerClasses: "text-right pr-18 col-md-2",
    },
  ];

  const handlAddNewProductCategory = () => {
    setAddDialogOpen(true);
  };

  if (error) {
    return (
      <Card {...props}>
        <CardHeader title="Product Groups Overview" {...props}>
          <CardActions></CardActions>
        </CardHeader>
        <CardBody {...props}>
          <div className="text-center">
            <p>Something went wrong. Please try again later, and if the problem persists - let us know in the #plant-tech-support slack channel.</p>
          </div>
        </CardBody>
      </Card>
    );
  }

  return (
    <>
      {alert && (
        <Alert variant={alert.variant} onClose={handleDismissAlert} dismissible>
          <Alert.Heading>{alert.title}</Alert.Heading>
          <p>{alert.message}</p>
          <p>{alert.additionalInfo}</p>
        </Alert>
      )}
      {apiCallInProgress && (
        <Spinner animation="border" role="status" variant="primary">
          <span className="sr-only">Loading...</span>
        </Spinner>
      )}
      <Card {...props}>
        <CardHeader title="Product Groups Overview" {...props}>
          <CardActions>
            <ImportFromFile formTitle="Import Product Group" enableDryRun={true} onImport={handleImport} onReset={() => setProductGroupNameToImport("")}>
              <div className="form-group">
                <div>
                  <Field
                    component={Input}
                    name="productGroupName"
                    value={productGroupNameToImport}
                    onChange={(event: any) => {
                      setProductGroupNameToImport(event.target.value);
                    }}
                    placeholder="Product Group Name"
                    label="Product Group Name"
                  />
                </div>
              </div>
            </ImportFromFile>
          </CardActions>
        </CardHeader>
        <CardBody {...props}>
          {isLoading ? (
            // Show loading spinner if data is loading
            <div className="text-center">
              <Spinner animation="border" role="status" variant="primary">
                <span className="sr-only">Loading...</span>
              </Spinner>
              <p className="mt-2">Loading...</p>
            </div>
          ) : isEmpty(productCategories) ? (
            <div className="text-center">
              <p>
                No product groups have been defined yet for this factory. You can use Add button to start configuring producible products.
              </p>
            </div>
          ) : (
            // Render the table if productCategories has data
            <BootstrapTable
              wrapperClasses="table-responsive column-width-control"
              classes="table table-head-custom table-vertical-center overflow-hidden"
              bootstrap4
              bordered={true}
              striped={true}
              remote
              keyField="productGroup"
              data={productGroupData}
              columns={columns}
              defaultSorted={[{ dataField: "productGroup", order: "asc" }]}
            ></BootstrapTable>
          )}
          <button
            title="New Product Group"
            className="btn btn-light btn-hover-primary btn-sm mx-3"
            onClick={handlAddNewProductCategory}
            style={{
              borderRadius: "12px",
              border: "2px solid #38BFC4",
              backgroundColor: "white",
              color: "#38BFC4",
              display: "flex",
              alignItems: "center",
              paddingLeft: "8px",
            }}
          >
            <span className="svg-icon svg-icon-md svg-icon-primary">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Code/Plus.svg")}
                title="New product group"
              />
            </span>
            <span style={{ marginLeft: "8px" }}>Add Product Group</span>
          </button>          
          {isAddDialogOpen && (
            <ProductCategoryAddDialog
              onHide={handleCancelClick}
              onSuccess={handleFormSubmitSuccess}
              plant={plant}
              productGroupNameUniquenessCheck={productGroupNameUniquenessCheck}
            />
          )}
          {isEditDialogOpen && (
            <ProductCategoryAddDialog
              onHide={handleCancelClick}
              onSuccess={handleFormSubmitSuccess}
              plant={plant}
              editProductGroup={selectedProductGroup}
            />
          )}
        </CardBody>
      </Card>
    </>
  );
};

export default ProductGroupsPage;
