import { ChangeEvent, useState } from "react";
// @ts-ignore
import { ValueEditorProps } from "react-querybuilder";

export const PercentageSlider = (props: ValueEditorProps) => {

    const [displayValue, setDisplayValue] = useState(props.value);
    const [changed, setChanged] = useState(false);

    const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {        
        let value = Number(e.target.value);
        if(value < 0 || value > 1)
        {
            value = displayValue;
        }
        setDisplayValue(value);
        setChanged(true);
    }

    const onMoveMouseOutSide = () => {
        if (changed) {
            props.handleOnChange(displayValue.toString());
            setChanged(false);
        }
    }

    return (
        <div onMouseLeave={onMoveMouseOutSide}>
            <input
                type="range"                
                value={displayValue}
                min={0}
                max={1}
                step={0.01}
                onChange={handleOnChange}
            />
            <input
                type="number"
                value={displayValue}
                onChange={handleOnChange}
                min={0}
                max={1}
                step="0.1"
            ></input>
        </div>
    );
};