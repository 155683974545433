import { useState, useEffect, FC, ReactElement } from "react";
import { Modal } from "react-bootstrap";
import { Carrier } from "../../models/CarrierModels";

export const CarrierEditDialogHeader: FC<{ carrier: Carrier }> = ({
  carrier,
}): ReactElement => {
  const [title, setTitle] = useState("");

  useEffect(() => {
    let _title = carrier ? "" : "New Carrier";
    if (carrier) {
      _title = `Edit carrier ${carrier.carrierName}`;
    }
    setTitle(_title);
  }, [carrier]);

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">{title}</Modal.Title>
      </Modal.Header>
    </>
  );
};