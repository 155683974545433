// @ts-ignore
import type { OptionGroup } from 'react-querybuilder';

export const articleCodes: OptionGroup[] = [
	{
		"label": "Aluminium",
		"types": [
			"PAP_498",
			"PAP_499",
			"PAP_500",
			"PAP_504",
			"PAP_505",
			"PAP_506",
			"PAP_507",
			"PAP_508",
			"PAP_509",
			"PAP_510",
			"PAP_511",
			"PAP_512",
			"PAP_513",
			"PAP_514",
			"PAP_515",
			"PAP_516",
			"PAP_517",
			"PAP_518",
			"PAP_519",
			"PAP_520",
			"PAP_521",
			"PAP_522",
			"PAP_523",
			"PAP_524",
			"PAP_525",
			"PAP_526",
			"PAP_527",
			"PAP_528",
			"PAP_529",
			"PAP_530",
			"PAP_531",
			"PAP_532",
			"PAP_533",
			"PAP_534",
			"PAP_535",
			"PAP_536",
			"PAP_537",
			"PAP_538",
			"PAP_539",
			"PAP_680",
			"PAP_681",
			"PAP_682",
			"PAP_683",
			"PAP_684",
			"PAP_685",
			"PAP_686",
			"PAP_687",
			"PAP_688"
		]
	},
	{
		"label": "AluminiumButler",
		"types": [
			"PAP_850",
			"PAP_851",
			"PAP_852",
			"PAP_853",
			"PAP_854",
			"PAP_855",
			"PAP_856",
			"PAP_857",
			"PAP_858",
			"PAP_859",
			"PAP_860",
			"PAP_861",
			"PAP_862",
			"PAP_863",
			"PAP_864",
			"PAP_865",
			"PAP_866",
			"PAP_867",
			"PAP_868",
			"PAP_869",
			"PAP_870",
			"PAP_871",
			"PAP_872",
			"PAP_873",
			"PAP_874",
			"PAP_875",
			"PAP_876",
			"PAP_877",
			"PAP_878",
			"PAP_879",
			"PAP_880",
			"PAP_881",
			"PAP_882",
			"PAP_883",
			"PAP_884",
			"PAP_885",
			"PAP_886",
			"PAP_887",
			"PAP_888",
			"PAP_889",
			"PAP_890",
			"PAP_891",
			"PAP_892",
			"PAP_893",
			"PAP_894",
			"PAP_895",
			"PAP_896",
			"PAP_897"
		]
	},
	{
		"label": "Calendar",
		"types": [
			"PAP_399",
			"PAP_401",
			"PAP_653",
			"PAP_658",
			"PAP_659",
			"PAP_660",
			"PAP_661",
			"PAP_662",
			"PAP_663",
			"PAP_664",
			"PAP_665",
			"PAP_666",
			"PAP_667",
			"PAP_668",
			"PAP_962",
			"PAP_963",
			"PAP_964",
			"PAP_965"
		]
	},
	{
		"label": "Canvas",
		"types": [
			"PAP_403",
			"PAP_404",
			"PAP_405",
			"PAP_408",
			"PAP_409",
			"PAP_410",
			"PAP_411",
			"PAP_412",
			"PAP_413",
			"PAP_414",
			"PAP_415",
			"PAP_416",
			"PAP_417",
			"PAP_418",
			"PAP_419",
			"PAP_420",
			"PAP_421",
			"PAP_422",
			"PAP_423",
			"PAP_424",
			"PAP_425",
			"PAP_426",
			"PAP_427",
			"PAP_428",
			"PAP_429",
			"PAP_430",
			"PAP_431",
			"PAP_432",
			"PAP_433",
			"PAP_434",
			"PAP_435",
			"PAP_436",
			"PAP_437",
			"PAP_438",
			"PAP_439",
			"PAP_440",
			"PAP_441",
			"PAP_442",
			"PAP_443",
			"PAP_444",
			"PAP_445",
			"PAP_446",
			"PAP_447",
			"PAP_448",
			"PAP_449",
			"PAP_700",
			"PAP_701",
			"PAP_702"
		]
	},
	{
		"label": "Canvas4cm",
		"types": [
			"PAP_730",
			"PAP_731",
			"PAP_732",
			"PAP_733",
			"PAP_734",
			"PAP_735",
			"PAP_736",
			"PAP_737",
			"PAP_738",
			"PAP_739",
			"PAP_740",
			"PAP_741",
			"PAP_742",
			"PAP_743",
			"PAP_744",
			"PAP_745",
			"PAP_746",
			"PAP_747",
			"PAP_748",
			"PAP_749",
			"PAP_750",
			"PAP_751",
			"PAP_752",
			"PAP_753",
			"PAP_754",
			"PAP_755",
			"PAP_756",
			"PAP_757",
			"PAP_758",
			"PAP_759",
			"PAP_760",
			"PAP_761",
			"PAP_762",
			"PAP_763",
			"PAP_764",
			"PAP_765",
			"PAP_766",
			"PAP_767",
			"PAP_768",
			"PAP_769",
			"PAP_770",
			"PAP_771",
			"PAP_772",
			"PAP_773",
			"PAP_774",
			"PAP_775",
			"PAP_776",
			"PAP_777"
		]
	},
	{
		"label": "Cushion",
		"types": [
			"PAP_920",
			"PAP_921",
			"PAP_922",
			"TEST_PAP_CUSHION_001"
		]
	},
	{
		"label": "CutoutPhotobook",
		"types": [
			"PAP_321"
		]
	},
	{
		"label": "Forex",
		"types": [
			"PAP_540",
			"PAP_541",
			"PAP_542",
			"PAP_543",
			"PAP_544",
			"PAP_545",
			"PAP_546",
			"PAP_547",
			"PAP_548",
			"PAP_549",
			"PAP_552",
			"PAP_553",
			"PAP_554",
			"PAP_555",
			"PAP_556",
			"PAP_557",
			"PAP_558",
			"PAP_559",
			"PAP_560",
			"PAP_561",
			"PAP_562",
			"PAP_563",
			"PAP_564",
			"PAP_565",
			"PAP_566",
			"PAP_567",
			"PAP_568",
			"PAP_569",
			"PAP_592",
			"PAP_593",
			"PAP_594",
			"PAP_595",
			"PAP_596",
			"PAP_597",
			"PAP_598",
			"PAP_599",
			"PAP_600",
			"PAP_604",
			"PAP_690",
			"PAP_691",
			"PAP_692",
			"PAP_693",
			"PAP_694",
			"PAP_695",
			"PAP_696",
			"PAP_697",
			"PAP_698",
			"PAP_699"
		]
	},
	{
		"label": "GreetingCard",
		"types": [
			"PAP_116",
			"PAP_119",
			"PAP_120",
			"PAP_376",
			"PAP_830",
			"PAP_831",
			"PAP_834",
			"PAP_835",
			"PAP_836",
			"PAP_837",
			"PAP_932",
			"PAP_933",
			"PAP_934",
			"PAP_935",
			"PAP_940",
			"PAP_941",
			"PAP_942",
			"PAP_943",
			"PAP_944",
			"PAP_945",
			"PAP_950",
			"PAP_951",
			"PAP_952",
			"PAP_953",
			"PAP_954",
			"PAP_955"
		]
	},
	{
		"label": "HardCoverPhotoBook",
		"types": [
			"PAP_130",
			"PAP_194",
			"PAP_201",
			"PAP_202",
			"PAP_324",
			"PAP_347",
			"PAP_348",
			"PAP_354",
			"PAP_355",
			"PAP_360"
		]
	},
	{
		"label": "Jigsaw",
		"types": [
			"PAP_970",
			"PAP_971",
			"PAP_972",
			"PAP_973",
			"PAP_974",
			"PAP_975",
			"PAP_977",
			"PAP_978",
			"PAP_979",
			"PAP_980",
			"PAP_981"
		]
	},
	{
		"label": "LoveMug",
		"types": [
			"PAP_722",
			"PAP_723"
		]
	},
	{
		"label": "MagicMug",
		"types": [
			"PAP_724",
			"PAP_725"
		]
	},
	{
		"label": "Magnet",
		"types": [
			"PAP_810",
			"PAP_811"
		]
	},
	{
		"label": "Mug",
		"types": [
			"PAP_720",
			"PAP_721",
			"TEST_PAP_MUG_001"
		]
	},
	{
		"label": "PaddedPhotobook",
		"types": [
			"PAP_193",
			"PAP_318",
			"PAP_319",
			"PAP_323",
			"PAP_328"
		]
	},
	{
		"label": "Perspex",
		"types": [
			"PAP_450",
			"PAP_451",
			"PAP_452",
			"PAP_453",
			"PAP_454",
			"PAP_455",
			"PAP_456",
			"PAP_457",
			"PAP_458",
			"PAP_459",
			"PAP_460",
			"PAP_461",
			"PAP_462",
			"PAP_463",
			"PAP_464",
			"PAP_465",
			"PAP_466",
			"PAP_467",
			"PAP_468",
			"PAP_469",
			"PAP_470",
			"PAP_471",
			"PAP_472",
			"PAP_473",
			"PAP_474",
			"PAP_475",
			"PAP_476",
			"PAP_477",
			"PAP_478",
			"PAP_479",
			"PAP_480",
			"PAP_481",
			"PAP_482",
			"PAP_483",
			"PAP_484",
			"PAP_485",
			"PAP_486",
			"PAP_487",
			"PAP_488",
			"PAP_489",
			"PAP_490",
			"PAP_491",
			"PAP_492",
			"PAP_493",
			"PAP_494",
			"PAP_495",
			"PAP_496",
			"PAP_497"
		]
	},
	{
		"label": "PhoneCase",
		"types": [
			"PAP_711",
			"PAP_712",
			"PAP_713",
			"PAP_714",
			"PAP_715",
			"PAP_716",
			"PAP_717",
			"PAP_718"
		]
	},
	{
		"label": "Photoblock",
		"types": [
			"PAP_780",
			"PAP_781",
			"PAP_782",
			"PAP_783",
			"PAP_784",
			"PAP_785",
			"PAP_786",
			"PAP_787",
			"PAP_788",
			"PAP_789",
			"PAP_790"
		]
	},
	{
		"label": "PhotoFlip",
		"types": [
			"PAP_400"
		]
	},
	{
		"label": "Poster",
		"types": [
			"PAP_610",
			"PAP_611",
			"PAP_612",
			"PAP_613",
			"PAP_614",
			"PAP_615",
			"PAP_616",
			"PAP_617",
			"PAP_618",
			"PAP_619",
			"PAP_620",
			"PAP_621",
			"PAP_622",
			"PAP_623",
			"PAP_624",
			"PAP_625",
			"PAP_626",
			"PAP_627",
			"PAP_628",
			"PAP_629",
			"PAP_630",
			"PAP_631",
			"PAP_632",
			"PAP_634",
			"PAP_635",
			"PAP_636",
			"PAP_637",
			"PAP_638",
			"PAP_639",
			"PAP_640",
			"PAP_641",
			"PAP_642",
			"PAP_643",
			"PAP_644",
			"PAP_645",
			"PAP_646",
			"PAP_647",
			"PAP_648",
			"PAP_649",
			"PAP_670",
			"PAP_671",
			"PAP_672",
			"PAP_673",
			"PAP_674",
			"PAP_675",
			"PAP_676",
			"PAP_677",
			"PAP_678",
			"PAP_703",
			"PAP_704",
			"PAP_705",
			"PAP_706",
			"PAP_707",
			"PAP_708",
			"PAP_709",
			"PAP_710",
			"PAP_998"
		]
	},
	{
		"label": "Prints",
		"types": [
			"PAP_910",
			"PAP_911",
			"PAP_912",
			"PAP_913",
			"PAP_914",
			"PAP_915"
		]
	},
	{
		"label": "SoftCoverPhotoBook",
		"types": [
			"PAP_109",
			"PAP_203",
			"PAP_204",
			"PAP_205",
			"PAP_349",
			"PAP_350"
		]
	},
	{
		"label": "StudentPlakat",
		"types": [
			"PAP_901",
			"PAP_902",
			"PAP_903"
		]
	},
	{
		"label": "Voucher",
		"types": [
			"PAP_050"
		]
	}
].map(({ label, types }) => ({
  label: label,
  options: types.map((s) => ({ name: s, label: s })),
}));