import { getCarriers, saveCarriers } from "../api/CarriersApi";
import { mapCarrierToICarrierApi, mapICarrierApiToCarrier } from "../mappers/CarrierMapper";
import { IAlertModel } from "../models/CarrierApiModels";
import { Carrier } from "../models/CarrierModels";
import { carrierSlice } from "./CarrierSlice";

const { actions } = carrierSlice;
const errorTitle = "Error while calling API";

export const fetchCarriers = (
  plantCode: string,
  carrierName?: string,
  countryCode?: string
) => async (dispatch: (args: any) => void) => {
  dispatch(actions.startCall());
  try {
    const { carriers } = await getCarriers(plantCode, carrierName, countryCode);
    const carriersMapped = carriers.map((carrier) => mapICarrierApiToCarrier(carrier));
    dispatch(actions.carriersFetched({ carriersMapped }));
  } catch (err) {
    const error = {
      title: errorTitle,
      message: "Can't find any carriers",
      variant: "danger",
    };
    console.error(err);
    dispatch(actions.catchError({ error }));
  }
};

export const updateCarrier = (carrier: Carrier) => async (
  dispatch: (args: { payload: any; type: string }) => void
) => {
  dispatch(actions.startCall());
  const carrierRequest =  mapCarrierToICarrierApi(carrier)
  await saveCarriers(carrierRequest)
    .then(() => {
      const alert = {
        title: "Carrier Updated",
        message: `${carrier.carrierName} has been updated`,
        variant: "success",
      } as IAlertModel;
      dispatch(actions.carrierUpdated({ carrier, alert }));
    })
    .catch((err) => {
      const error = {
        title: errorTitle,
        message: `Can't update selected carrier: ${carrier.carrierName}`,
        variant: "danger",
      } as IAlertModel;
      console.error(err);
      dispatch(actions.catchError({ error }));
    });
};

export const selectCarrier = (carrier: Carrier) => (
  dispatch: (args: { payload: any; type: string }) => void
) => {
  dispatch(actions.carrierSelected({ carrier }));
};

export const unselectCarrier = () => (
  dispatch: (args: { payload: any; type: string }) => void
) => {
  dispatch(actions.carrierUnselected());
};
