import { useState } from "react";
import PlantRoutingRulesCard from "../components/PlantRoutingRulesCard";
import PlantRoutingRuleProvider, { usePlantRoutingRuleContext } from "../PlantRoutingRuleContext";
import PlantRoutingRuleDetailModal from "../components/PlantRoutingRuleDetailModal";

const PlantRoutingRulesPage = () => {
  const [showRuleDetailsModal, setShowRuleDetailsModal] = useState<boolean>(false);

  const handleShowRuleDetailsModal = () => {
    setShowRuleDetailsModal(true)
  }

  const handleHideRuleDetailsModal = () => {
    setShowRuleDetailsModal(false)
  }

  const plantRoutingRulesUIEvents = {
    showRuleDetailsModal: handleShowRuleDetailsModal,
    hideRuleDetailsModal: handleHideRuleDetailsModal
  }

  return (
    <PlantRoutingRuleProvider plantRoutingRulesUIEvents={plantRoutingRulesUIEvents}>
      <PlantRoutingRulesCard />
      {showRuleDetailsModal && <PlantRoutingRuleDetailModal />}
    </PlantRoutingRuleProvider>
  )
}

export { PlantRoutingRulesPage };