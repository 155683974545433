/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { useCarrierConfigurationSupport } from "../../../../../app/components/PlantHook";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import { useMsal } from "@azure/msal-react";

export function AsideMenuList() {
  const location = useLocation();

  const [isDropdownMenuOpen, setDropdownMenuOpen] = useState(false);

  const toggleDropdownMenu = () => {
    setDropdownMenuOpen(!isDropdownMenuOpen);
  };

  // Function which determines is menu or submenu item active so it can apply correct CSS classes
  const getMenuItemActive = (url: string, hasSubmenu = false) => {
    const isActive = checkIsActive(location, url);

    if (isActive) {
      return `menu-item-active menu-item-open menu-item-not-hightlighted`;
    }

    // Check if any of the sub-menu items is active
    if (hasSubmenu) {
      const subMenuItems = ["/products", "/packaging, /pack-an-order"];
      const isSubMenuActive = subMenuItems.some((subMenuItem) =>
        checkIsActive(location, subMenuItem)
      );

      if (isSubMenuActive) {
        return `menu-item-active menu-item-open menu-item-not-hightlighted`;
      }
    }

    return "";
  };

  const getSubMenuItemActive = (url: string) => {
    return checkIsActive(location, url) ? "sub-menu-item-active" : "";
  };

  const getHelpLink = () => {
    const baseUrl = "https://my-factory.virtual-plant.infra.photos/wiki/packaging-configuration.pdf";
    const timestamp = new Date().getTime();
    return `${baseUrl}?v=${timestamp}`;
  };

  const plantSupportsCarrierConfiguration = useCarrierConfigurationSupport();
  const { accounts }: any = useMsal();
  var roles = accounts[0].idTokenClaims.roles as Array<string> ?? [];
  var developerMode = roles.filter(x => x === "Developer").length > 0;

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className="menu-nav">
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/audit-logs", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/audit-logs">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">Audit Logs</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/holidays", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/holidays">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">Holidays</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        {plantSupportsCarrierConfiguration && (
          <li
            className={`menu-item ${getMenuItemActive("/carriers", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/carriers">
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")}
                />
              </span>
              <span className="menu-text">Carriers</span>
            </NavLink>
          </li>
        )}
        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        {plantSupportsCarrierConfiguration && (
          <li
            className={`menu-item ${getMenuItemActive(
              "/carrier-overrides",
              false
            )}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/carrier-overrides">
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")}
                />
              </span>
              <span className="menu-text">Carrier Overrides</span>
            </NavLink>
          </li>
        )}
        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/leadtimes", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/leadtimes">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">Lead Times</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/routing-rules", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/routing-rules">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">Routing Rules</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/delivery-options", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/delivery-options">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">Delivery Options</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        {developerMode && (
          <li
            className={`menu-item ${getMenuItemActive("/skuMappings", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/skuMappings">
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")}
                />
              </span>
              <span className="menu-text">Sku Mappings</span>
            </NavLink>
          </li>
        )}
        {/*end::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/packaging", true)}`}
          aria-haspopup="true"
          onMouseEnter={toggleDropdownMenu}
          onMouseLeave={toggleDropdownMenu}
        >
          <span className="menu-link">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">Packaging</span>
            <span className="menu-desc">▼</span>
          </span>

          {/* Dropdown */}
          {isDropdownMenuOpen ||
            getSubMenuItemActive("/products") ||
            getSubMenuItemActive("/packaging") ||
            getSubMenuItemActive("/pack-an-order") ||
            getSubMenuItemActive("/bundlegroups") ||
            getSubMenuItemActive("/help") ? (
            <ul className="sub-menu">
              <li
                className={`menu-item ${getSubMenuItemActive("/products")}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/products">
                  <span
                    className={`menu-text ${getSubMenuItemActive("/products")}`}
                    style={{
                      color: getSubMenuItemActive("/products")
                        ? "#3699ff"
                        : "#fff",
                    }}
                  >
                    Products
                  </span>
                </NavLink>
              </li>
              <li
                className={`menu-item ${getSubMenuItemActive(
                  "/test-product-dimensions"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/test-product-dimensions">
                  <span
                    className={`menu-text ${getSubMenuItemActive(
                      "/test-product-dimensions"
                    )}`}
                    style={{
                      color: getSubMenuItemActive("/test-product-dimensions")
                        ? "#3699ff"
                        : "#fff",
                    }}
                  >
                    Test Products
                  </span>
                </NavLink>
              </li>
              <li
                className={`menu-item ${getSubMenuItemActive("/packaging")}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/packaging">
                  <span
                    className={`menu-text ${getSubMenuItemActive(
                      "/packaging"
                    )}`}
                    style={{
                      color: getSubMenuItemActive("/packaging")
                        ? "#3699ff"
                        : "#fff",
                    }}
                  >
                    Packages
                  </span>
                </NavLink>
              </li>              
              <li
                className={`menu-item ${getSubMenuItemActive("/bundlegroups")}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/bundlegroups">
                  <span
                    className={`menu-text ${getSubMenuItemActive("/bundlegroups")}`}
                    style={{
                      color: getSubMenuItemActive("/bundlegroups")
                        ? "#3699ff"
                        : "#fff",
                    }}
                  >
                    Bundle Groups
                  </span>
                </NavLink>
              </li>
              <li
                className={`menu-item ${getSubMenuItemActive(
                  "/pack-an-order"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/pack-an-order">
                  <span
                    className={`menu-text ${getSubMenuItemActive(
                      "/pack-an-order"
                    )}`}
                    style={{
                      color: getSubMenuItemActive("/pack-an-order")
                        ? "#3699ff"
                        : "#fff",
                    }}
                  >
                    Test Packages
                  </span>
                </NavLink>
              </li>
              <li className={`menu-item ${getSubMenuItemActive("/help")}`} aria-haspopup="true">
                <a
                  className="menu-link"
                  href={getHelpLink()}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    color: getSubMenuItemActive("/help") ? "#3699ff" : "#fff",
                    textDecoration: 'none'
                  }}
                >
                  <span className={`menu-text ${getSubMenuItemActive("/help")}`}>
                    Help
                  </span>
                </a>
              </li>
            </ul>
          ) : null}
        </li>
        {/* end::1 Level */}
        {/*end::1 Level*/}

      </ul>
      {/* end::Menu Nav */}
    </>
  );
}
