import { FC, ReactElement, Suspense } from "react";
import { Redirect, Switch } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import LeadTimesPage from "./leadTimes/pages/LeadTimesPage";
import ProductGroupPage from "./leadTimes/pages/ProductGroupPage";
import { Props } from "./models/PropsModel";
import { AuditLogsPage } from "./auditLogs/pages/AuditLogsPage";
import { HolidaysPage } from "./holidays/pages/HolidaysPage";
import { CarriersPage } from "./carrier/pages/CarriersPage";
import { CarrierOverridesPage } from "./carrierOverrides/pages/CarrierOverridesPage";
import { RoutingRulesPage } from "./routingRules/pages/RoutingRulesPage";
import TestDeliveryOptionsPage from "./testDeliveryOptions/pages/TestDeliveryOptionsPage";
import ProductGroupsPage from "./products/pages/ProductGroupsPage";
import PackagingPage from "./packaging/pages/PackagingPage";
import EditPackagingGroupPage from "./packaging/pages/EditPackagingGroupPage";
import ProductGroupDetails from "./products/pages/ProductGroupDetails";
import PackAnOrder from "./playground/pages/PackAnOrder";
import { SkuMappingsPage } from "./skuMappings/pages/SkuMappingsPage";
import TestProductDimensions from "./playground/pages/TestProductDimensions";
import BundleGroupPage from "./deliveryGroups/pages/BundleGroupPage";
import EditBundleGroupPage from "./deliveryGroups/pages/EditBundleGroupPage";
import { PlantRoutingRulesPage } from "./plantRoutingRules/pages/PlantRoutingRulesPage";

const BasePage: FC<Props> = ({ props }): ReactElement => {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        <ContentRoute
          path="/audit-logs"
          component={AuditLogsPage}
          {...props}
        />
        <ContentRoute
          path="/holidays"
          component={HolidaysPage}
          {...props}
        />
        <ContentRoute
          path="/leadtimes/:productCategory"
          component={ProductGroupPage}
          {...props}
        />
        <ContentRoute
          path="/leadtimes"
          component={LeadTimesPage}
          {...props}
        />
        <ContentRoute
          path="/carriers"
          component={CarriersPage}
          {...props}
        />
        <ContentRoute
          path="/skuMappings"
          component={SkuMappingsPage}
          {...props}
        />
        <ContentRoute
          path="/carrier-overrides"
          component={CarrierOverridesPage}
          {...props}
        />
        <ContentRoute
          path="/routing-rules"
          component={RoutingRulesPage}
          {...props}
        />
        <ContentRoute
          path="/plant-routing-rules"
          component={PlantRoutingRulesPage}
          {...props}
        />
        <ContentRoute
          path="/delivery-options"
          component={TestDeliveryOptionsPage}
          {...props}
        />
        <ContentRoute
          exact
          path="/products"
          component={ProductGroupsPage}
          {...props}
        />
        <ContentRoute
          path="/products/:productCategory"
          component={ProductGroupDetails}
          {...props}
        />
        <ContentRoute
          exact
          path="/packaging"
          component={PackagingPage}
          {...props}
        />
        <ContentRoute
          path="/packaging/:packagingGroup"
          component={EditPackagingGroupPage}
          {...props}
        />
        <ContentRoute
          path="/bundlegroups"
          component={BundleGroupPage}
          {...props}
        />
        <ContentRoute
          path="/singlebundlegroup/:deliveryGroup"
          component={EditBundleGroupPage}
          {...props}
        />
        <ContentRoute
          path="/pack-an-order"
          component={PackAnOrder}
          {...props}
        />
        <ContentRoute
          path="/test-product-dimensions"
          component={TestProductDimensions}
          {...props}
        />
        <Redirect from="/" exact to="/delivery-options" />
        <Redirect to="/error/error-v1" />
      </Switch>
    </Suspense>
  );
};

export default BasePage;
