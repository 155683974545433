import { Modal } from "react-bootstrap";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Input } from "../../../../_metronic/_partials/controls";
import { ILocation } from "../../models/CarrierApiModels";
import { formatCountryName } from "../../helpers/CountryHelper";
import { getZipCodeRangeFormatted } from "../../helpers/ZipcodeHelper";

const daysOfTheWeekRegex = new RegExp("(^[1]{0,1}[2]{0,1}[3]{0,1}[4]{0,1}[5]{0,1}[6]{0,1}[7]{0,1})$");

const CarrierEditValidationSchema = Yup.object().shape({
  pickupDays: Yup.string().matches(daysOfTheWeekRegex, 'Should have only valid days of the week in consecutive order'),
  deliveryDays: Yup.string().matches(daysOfTheWeekRegex, 'Should have only valid days of the week in consecutive order'),
  locations: Yup.array().of(
    Yup.object().shape({
      shippingLeadTime: Yup.number()
        .positive("Should be greater than 0")
        .integer("Only whole numbers allowed")
        .required("Field is required"),
    })
  ),
});

export function CarrierEditForm({ saveCarrier, carrier, onHide }: any) {
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={carrier}
        validationSchema={CarrierEditValidationSchema}
        onSubmit={(values) => {
          saveCarrier(values);
        }}
      >
        {({ handleSubmit }) => (
          <>
            <Modal.Body className="overlay overlay-block cursor-default">
              <Form className="form form-label-right">
              <div className="form-group row">
                <div className="col-lg-3">
                <label htmlFor="pickupDays">Pickup Days</label>
                  <Field
                    name="pickupDays"
                    component={Input}
                    type="string"
                  />
                </div>
                <div className="col-lg-3">
                <label htmlFor="deliveryDays">Delivery Days</label>
                  <Field
                    name="deliveryDays"
                    component={Input}
                    type="string"
                  />
                </div>
                <div className="col-lg-6">
                  <label htmlFor="shippingCutOffTime">Shipping Cutoff Time</label>
                  <div style={{display: "inline-flex"}}>
                    <Field
                      name="shippingCutOffTime"
                      component={Input}
                      type="time"
                    />
                    <div style={{color: "gray", marginLeft: "1em"}}>
                      <div>Local</div>
                      <div>Time</div>
                    </div>
                  </div>
                </div>
              </div>
              <div><h3>Locations</h3></div>
                <FieldArray
                  name="locations"
                  render={() => (
                    <div>
                      {carrier !== undefined &&
                        carrier.locations
                          .map((location: ILocation, index: number) => {
                            return (
                              <div key={index}>
                                <div className="form-group row">
                                  <div className="col-lg-2">
                                    {index === 0 ? (
                                      <label htmlFor="country">Country</label>
                                    ) : (
                                      ""
                                    )}
                                    <Field
                                      name={`locations[${index}].countryId`}
                                      component={Input}
                                      value={formatCountryName(carrier.locations[index])}
                                      type="string"
                                      disabled={true}
                                    />
                                  </div>
                                  <div className="col-lg-2">
                                    {index === 0 ? (
                                      <label htmlFor="inEu">in EU</label>
                                    ) : (
                                      ""
                                    )}
                                    <Field
                                      name={`locations[${index}].inEu`}
                                      component={Input}
                                      disabled={true}
                                      type="string"
                                    />
                                  </div>
                                  <div className="col-lg-4">
                                    {index === 0 ? (
                                      <label htmlFor="zipCodeRanges">
                                        Zip Code Ranges
                                      </label>
                                    ) : (
                                      ""
                                    )}
                                    {carrier.locations[index].zipCodeRanges
                                      .length > 0 ? (
                                      <Field
                                        component={Input}
                                        disabled={true}
                                        value={getZipCodeRangeFormatted(
                                          carrier.locations[index].zipCodeRanges
                                        )}
                                        type="string"
                                      />
                                    ) : (
                                      <Field
                                        component={Input}
                                        disabled={true}
                                        value="-"
                                        type="string"
                                      />
                                    )}
                                  </div>
                                  <div className="col-lg-3">
                                    {index === 0 ? (
                                      <label htmlFor="shippingLeadTime">
                                        Shipping Lead Time
                                      </label>
                                    ) : (
                                      ""
                                    )}
                                    <Field
                                      name={`locations[${index}].shippingLeadTime`}
                                      component={Input}
                                      type="number"
                                    />
                                    <ErrorMessage
                                      name={`locations[${index}].shippingLeadTime`}
                                    />
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                    </div>
                  )}
                />
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                onClick={onHide}
                className="btn btn-light btn-elevate"
              >
                Cancel
              </button>
              <> </>
              <button
                type="submit"
                onClick={() => handleSubmit()}
                className="btn btn-primary btn-elevate"
              >
                Save
              </button>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </>
  );
}
