import React, { useState, useRef, useEffect } from 'react';
import { generateUniqueId } from "../../app/Tools";

function MultiSelectDropdownWithCheckboxes({ options, selectedOptions=[], onSelect, isLocked, label = "Choose", controlId = "", extraData = ""}) {
  if (controlId === "" || controlId == null || controlId === undefined) {
    controlId = "MultiSelectDropdownWithCheckboxes" + generateUniqueId(5);
  }

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedDescriptions, setSelectedDescriptions] = useState(selectedOptions.map(option => option.description).join(", "));
  const dropdownRef = useRef(null);

  const handleChange = (optionKey) => {
    const isOptionSelected = selectedOptions.some(selectedOption => selectedOption.key === optionKey);
    const newSelectedValues = isOptionSelected
      ? selectedOptions.filter(selectedOption => selectedOption.key !== optionKey)
      : [...selectedOptions, options.find(option => option.key === optionKey)];
    if (extraData !== undefined && extraData != null && extraData !== "") {
      onSelect(newSelectedValues, extraData);
    } else {
      onSelect(newSelectedValues);
    }
    if (!isOptionSelected && !selectedOptions.some(selectedOption => selectedOption.key === optionKey)){
      selectedOptions.push(options.find(o => o.key === optionKey));
    }
    else if (isOptionSelected && selectedOptions.some(selectedOption => selectedOption.key === optionKey))
    {
      let idx = selectedOptions.findIndex(o => o.key === optionKey);
      if (idx > -1)
        selectedOptions.splice(idx, 1);
    }
    setSelectedDescriptions(selectedOptions.map(option => option.description).join(", "));
};

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    setSelectedDescriptions(selectedOptions.map(option => option.description).join(", "));
    return () => document.removeEventListener("mousedown", handleClickOutside);    
  }, []);

  useEffect(() => {
    if (selectedOptions == null || selectedOptions === undefined || selectedOptions.length === 0) {
      setSelectedDescriptions("")  ;
    } else {
      setSelectedDescriptions(selectedOptions.map(option => option.description).join(", "));
    }
  }, [selectedOptions]);
  

  return (
    <div ref={dropdownRef} style={{ position: 'relative' }} key={controlId}>
      <div
        onClick={() => !isLocked && setIsDropdownOpen(!isDropdownOpen)}
        style={{ border: '1px solid #ccc', padding: '10px', cursor: isLocked ? 'not-allowed' : 'pointer' }}
      >
         {selectedDescriptions || label}
      </div>
      {isDropdownOpen && (
        <div style={{ position: 'absolute', border: '1px solid #ccc', padding: '10px', backgroundColor: 'white', zIndex: 100 }}>
          {options.map(option => {
            const isChecked = selectedOptions.some(selectedOption => selectedOption.key === option.key);
            return (
              <div key={option.key}>
                <input
                  type="checkbox"
                  id={controlId+option.key}
                  value={option.key}
                  checked={isChecked}
                  onChange={() => handleChange(option.key)}
                  disabled={isLocked}
                />
                <label htmlFor={controlId+option.key} style={{ cursor: 'pointer' }}>{option.description}</label>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default MultiSelectDropdownWithCheckboxes;
