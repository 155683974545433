import React, {
  useRef,
  Dispatch,
  SetStateAction,
  useReducer,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Modal } from 'react-bootstrap';
import { uploadLeadTimes as uploadLeadTimesApi } from '../../api/ProductLeadTimeApi';
import { Alert, Spinner } from 'react-bootstrap';
import { reducer, initialState } from './reducer';
import { addDays } from 'date-fns';
import LeadTimesDryRunUpdates from '../leadtimes-dryrun-updates/LeadTimesDryRunUpdates';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.min.css';
import './style.scss';
import { IScheduleData } from '../../../models/LeadTimes';
import { shallowEqual } from 'react-redux';
import { useAppSelector } from '../../../../app/hooks';
import { RootState } from '../../../../redux/store';

export type Props = {
  show: boolean;
  plantCode: string;
  canScheduleLeadTimes: boolean;
  setShowImportModal: Dispatch<SetStateAction<boolean>>;
  setHasLeadTimesUpdated: Dispatch<SetStateAction<boolean>>;
};

const initialFileSchedulingData: IScheduleData = {
  id: '',
  name: '',
  startDate: null,
  endDate: null,
  startDateLocal: null,
  endDateLocal: null,
  isActive: false,
};

const LeadTimesImportModal = (props: Props) => {
  const currentState = useAppSelector(
    (state: RootState) => state.products,
    shallowEqual
  );
  const { schedules } = currentState;
  const chooseFileButton = useRef<HTMLInputElement>(null);
  const [state, dispatch] = useReducer(reducer, initialState);
  const [fileSchedulingData, setFileSchedulingData] = useState(
    initialFileSchedulingData
  );
  const [isScheduleSelected, setIsScheduleSelected] = useState<boolean>(false);
  const [scheduleAction, setScheduleAction] = useState<string>('new');
  const todayDate = new Date();
  todayDate.setHours(0, 0, 0, 0);
  const tomorrowDate = new Date(todayDate);
  tomorrowDate.setDate(todayDate.getDate() + 1);

  const scheduleDateCopyAfterDryRun = isScheduleSelected
    ? `Lead times updates will be scheduled to start on ${fileSchedulingData?.startDate} and end on ${fileSchedulingData?.endDate} when 'Accept' is clicked.`
    : `Lead times updates will take effect immediately when 'Accept' is clicked.`;

  const uploadLeadTimes = useCallback(
    async (dryRun: boolean, fileSchedulingData?: IScheduleData) => {
      try {
        props.setHasLeadTimesUpdated(false);
        dispatch({ type: 'is_uploading' });
        const data = await uploadLeadTimesApi(
          state.file,
          props.plantCode,
          dryRun,
          fileSchedulingData
        );
        if (dryRun) {
          dispatch({ type: 'dry_run_success', dryRunResponseData: data });
        } else {
          props.setHasLeadTimesUpdated(true);
          dispatch({ type: 'upload_success' });
        }
      } catch (error: any) {
        const errorMessage = error?.response?.data?.Message ?? 'Request failed';
        if (dryRun) {
          dispatch({ type: 'dry_run_failed', errorMessage });
        } else {
          dispatch({ type: 'upload_failed', errorMessage });
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.plantCode, state.file]
  );

  useEffect(() => {
    if (chooseFileButton && chooseFileButton.current && !state.file) {
      chooseFileButton.current.value = '';
    }
    clearSchedulingData(); // Need to clear after successful csv upload
  }, [state.file]);

  const title = `Upload Product Lead Times for ${props.plantCode}`;

  const fileChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      dispatch({ type: 'set_file', file: e.target.files[0] });
    }
  };

  const handleFileSchedulingInputChange = (data: any, name: string) => {
    setFileSchedulingData({
      ...fileSchedulingData,
      [name]: data,
    });
  };

  const handleScheduleNamesChange = (id: string) => {
    const schedule = schedules.find(schedule => schedule.id === id);
    if (schedule) {
      setFileSchedulingData({
        ...fileSchedulingData,
        id: schedule.id,
        name: schedule.name,
      });
    };
  };

  const clearSchedulingData = () => {
    setIsScheduleSelected(false);
    setFileSchedulingData(initialFileSchedulingData);
    setScheduleAction('new');
  };

  const handleRadioChange = (value: boolean) => {
    setIsScheduleSelected(value);
  };

  const handleScheduleActionChange = (action: string) => {
    setScheduleAction(action);
    setFileSchedulingData(fileSchedulingData => ({
      ...fileSchedulingData,
      id: '',
      name: ''
    }));
  };

  const leadTimesDiscardDryRunHandler = () => {
    clearSchedulingData();
    dispatch({ type: 'set_file', file: null });
  };

  // Note: Need to wrap component in Form onSubmit in order to get proper validation for datepicker
  const handleLeadTimesValidatedSubmit = (
    e: React.FormEvent<HTMLFormElement>,
    isDryRun: boolean
  ) => {
    e.preventDefault();
    leadTimesUploadHandler(isDryRun);
  };

  const leadTimesUploadHandler = (isDryRun: boolean) => {
    if (isScheduleSelected) {
      uploadLeadTimes(isDryRun, fileSchedulingData);
    } else {
      uploadLeadTimes(isDryRun);
    }
  };

  const hideImportModalHandler = () => {
    props.setShowImportModal(false);
    clearSchedulingData();
    dispatch({ type: 'reset_import' });
  };

  const isAlert = state.success || state.error;
  const alertType = isAlert && state.success ? 'success' : 'error';
  const alertsData = {
    success: {
      title: 'Success',
      variant: 'success',
      message: state.successMessage,
    },
    error: {
      title: 'Error',
      variant: 'danger',
      message: state.errorMessage,
    },
  };

  const hasLeadTimesChanged =
    !!state?.dryRunResponseData &&
    (state?.dryRunResponseData?.numberOfNewRecords !== 0 ||
      state?.dryRunResponseData?.numberOfUpdatedRecords !== 0);

  return (
    <Modal
      show={props.show}
      onHide={hideImportModalHandler}
      dialogClassName='modal-xl leadtime'
      backdrop='static'
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title id='example-modal-sizes-title-lg'>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className='overlay overlay-block cursor-default'>
        {isAlert && (
          <Alert variant={alertsData[alertType].variant}>
            <Alert.Heading>{alertsData[alertType].title}</Alert.Heading>
            <p>{alertsData[alertType].message}</p>
          </Alert>
        )}
        {!hasLeadTimesChanged && (
          <div className='row'>
            <div className='col-lg-12'>
              <label htmlFor='file' className='sr-only'>
                Choose a file
              </label>
              <input
                id='file'
                type='file'
                ref={chooseFileButton}
                accept='.csv'
                onChange={fileChangeHandler}
                className='btn btn-primary-theme btn-elevate'
              />
            </div>
          </div>
        )}
        {state.isLoading && (
          <div className='text-center mt-5'>
            <Spinner animation='border' role='status' variant='primary'>
              <span className='sr-only'>Loading...</span>
            </Spinner>
            <p className='mt-2'>Loading...</p>
          </div>
        )}
        {!state.isLoading && state.file && !hasLeadTimesChanged && (
          <form onSubmit={(e) => handleLeadTimesValidatedSubmit(e, true)}>
            <div className='row mt-4'>
              {props.canScheduleLeadTimes && (
                <div className='col-12 mt-2 mb-4'>
                  <div className='form-check form-check-inline mb-2'>
                    <input
                      className='form-check-input'
                      type='radio'
                      name='immediate'
                      id='immediate'
                      value='immediate'
                      checked={isScheduleSelected === false}
                      onChange={() => handleRadioChange(false)}
                    />
                    <label className='form-check-label' htmlFor='immediate'>
                      Immediate
                    </label>
                  </div>
                  <div className='form-check form-check-inline mb-2'>
                    <input
                      className='form-check-input'
                      type='radio'
                      name='asSchedule'
                      id='asSchedule'
                      value='asSchedule'
                      checked={isScheduleSelected === true}
                      onChange={() => handleRadioChange(true)}
                    />
                    <label className='form-check-label' htmlFor='asSchedule'>
                      As schedule
                    </label>
                  </div>
                  {isScheduleSelected && (
                    <div className='schedule-data-container w-md-75 w-sm-100'>
                      <div className='form-check form-check-inline mb-6'>
                        <input
                          className='form-check-input'
                          type='radio'
                          name='scheduleAction'
                          id='newSchedule'
                          value='new'
                          checked={scheduleAction === 'new'}
                          onChange={() => handleScheduleActionChange('new')}
                        />
                        <label className='form-check-label' htmlFor='newSchedule'>
                          New
                        </label>
                      </div>
                      <div className='form-check form-check-inline mb-6'>
                        <input
                          className='form-check-input'
                          type='radio'
                          name='scheduleAction'
                          id='updateSchedule'
                          value='update'
                          checked={scheduleAction === 'update'}
                          onChange={() => handleScheduleActionChange('update')}
                        />
                        <label className='form-check-label' htmlFor='updateSchedule'>
                          Update
                        </label>
                      </div>
                      {scheduleAction === 'new' && (
                        <div className='form-inline mb-6'>
                          <label htmlFor='name' className='mr-4'>
                            Schedule name:
                          </label>
                          <input
                            className='form-control form-control-md flex-fill'
                            type='text'
                            placeholder='Schedule name'
                            id='name'
                            name='name'
                            value={fileSchedulingData.name}
                            onChange={(e) =>
                              handleFileSchedulingInputChange(
                                e.target.value,
                                'name'
                              )
                            }
                            required
                          />
                        </div>
                      )}
                      {scheduleAction === 'update' && (
                        <div className='form-inline mb-6'>
                          <label htmlFor='scheduleNames' className='mr-4'>Schedule name:</label>
                          <select
                            className='form-control form-control-md flex-fill'
                            id='scheduleNames'
                            name='scheduleNames'
                            value={fileSchedulingData.id}
                            onChange={(e) => handleScheduleNamesChange(e.target.value)}
                            required
                          >
                            <option value='' disabled>
                              Select a schedule
                            </option>
                            {schedules.map((schedule) => (
                              <option key={schedule.id} value={schedule.id}>{schedule.name}</option>
                            ))}
                          </select>
                        </div>
                      )}
                      <div className='form-inline'>
                        <label htmlFor='startDate' className='mr-7'>
                          Start date:
                        </label>
                        <DatePicker
                          className='form-control form-control-sm'
                          showIcon
                          todayButton='Today'
                          selected={fileSchedulingData?.startDate}
                          onChange={(date) => {
                            if (date !== null) {
                              handleFileSchedulingInputChange(
                                date,
                                'startDate'
                              );
                            }
                          }}
                          minDate={todayDate}
                          maxDate={
                            fileSchedulingData?.endDate
                              ? addDays(fileSchedulingData?.endDate, -1)
                              : null
                          }
                          showTimeSelect
                          dateFormat='d/MM/yyyy h:mm aa'
                          placeholderText="Select a start date"
                          required
                        />
                        <label htmlFor='endDate' className='mr-4 ml-4'>
                          End date:
                        </label>
                        <DatePicker
                          className='form-control form-control-sm'
                          showIcon
                          selected={fileSchedulingData?.endDate}
                          onChange={(date) => {
                            if (date !== null) {
                              handleFileSchedulingInputChange(date, 'endDate');
                            }
                          }}
                          minDate={
                            fileSchedulingData?.startDate
                              ? addDays(fileSchedulingData?.startDate, 1)
                              : tomorrowDate
                          }
                          showTimeSelect
                          dateFormat='d/MM/yyyy h:mm aa'
                          disabled={!fileSchedulingData?.startDate}
                          placeholderText="Select an end date"
                          required
                        />
                      </div>
                    </div>
                  )}
                </div>
              )}
              <div className='col-12'>
                <button
                  type='submit'
                  className='btn btn-primary-theme text-right'
                >
                  Upload
                </button>
              </div>
            </div>
          </form>
        )}
        {!state.isLoading &&
          state.file &&
          state.dryRunResponseData &&
          hasLeadTimesChanged && (
            <>
              <LeadTimesDryRunUpdates
                dryRunResponseData={state.dryRunResponseData}
              />
              <div className='row'>
                {props.canScheduleLeadTimes && (
                  <div className='col-12'>
                    <Alert variant='secondary'>
                      {scheduleDateCopyAfterDryRun}
                    </Alert>
                  </div>
                )}
              </div>
              <div className='row'>
                <div className='col-12 align-self-end'>
                  <div
                    className={props.canScheduleLeadTimes ? 'text-right' : ''}
                  >
                    <button
                      type='button'
                      className='btn btn-outline-theme border-3 fw-bold mr-5 mb-sm-5 mb-md-0'
                      onClick={leadTimesDiscardDryRunHandler}
                    >
                      Discard
                    </button>
                    <button
                      type='submit'
                      className='btn btn-primary-theme border-3 fw-bold mb-sm-5 mb-md-0'
                      onClick={() => leadTimesUploadHandler(false)}
                    >
                      Accept
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}
      </Modal.Body>
      <Modal.Footer>
        <button
          type='button'
          className='btn btn-light'
          onClick={hideImportModalHandler}
        >
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default LeadTimesImportModal;
