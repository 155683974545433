import React, { createContext, ReactNode, useState, useContext } from "react";
import { IPlantRoutingRule } from "./models/PlantRoutingRuleSetModel";

type PlantRoutingRuleContextProps = {
    selectedRoutingRule?: IPlantRoutingRule,
    setSelectedRoutingRule: (selectedRoutingRule: IPlantRoutingRule) => void,
    uiEvents: PlantRoutingRuleUIEvents
}

type PlantRoutingRuleUIEvents = {
    showRuleDetailsModal: () => void,
    hideRuleDetailsModal: () => void
}

const initialState: PlantRoutingRuleContextProps = {
    selectedRoutingRule: undefined,
    setSelectedRoutingRule: () => { },
    uiEvents: {
        showRuleDetailsModal: () => { },
        hideRuleDetailsModal: () => { }
    }
};

const PlantRoutingRuleContext = createContext<PlantRoutingRuleContextProps>(initialState);

export function usePlantRoutingRuleContext() {
    return useContext(PlantRoutingRuleContext);
}

type Props = {
    children: ReactNode,
    plantRoutingRulesUIEvents: PlantRoutingRuleUIEvents
};

const PlantRoutingRuleProvider = (props: Props) => {
    const [selectedRoutingRule, setSelectedRoutingRule] = useState<IPlantRoutingRule>();
    const value = {
        selectedRoutingRule,
        setSelectedRoutingRule,
        uiEvents: props.plantRoutingRulesUIEvents
    };
    return <PlantRoutingRuleContext.Provider value={value}>{props.children}</PlantRoutingRuleContext.Provider>;
}

export default PlantRoutingRuleProvider;