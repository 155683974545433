import { FC, ReactElement } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { Carrier } from "../../models/CarrierModels";
import { countriesColumnFormatter, shippingLeadTimesColumnFormatter, actionsColumnFormatter } from "./ColumnsFormatter";

export const CarriersTable: FC<{
  carriers: Carrier[];
  handleEditClick: (carrier: Carrier) => void;
}> = ({ carriers, handleEditClick }): ReactElement => {
  
 const columns = [
    {
      dataField: "carrierName",
      text: "Carrier",
    },
    {
      dataField: "Countries",
      text: "Countries",
      formatter: countriesColumnFormatter,
    },
    {
      dataField: "Shipping Lead Times",
      text: "Shipping Lead Times",
      formatter: shippingLeadTimesColumnFormatter,
    },
    {
      dataField: "action",
      text: "Edit",
      formatter: actionsColumnFormatter,
      formatExtraData: { handleEditClick },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
    },
  ];

  return (
    <BootstrapTable
      wrapperClasses="table-responsive"
      bordered={false}
      classes="table table-head-custom table-vertical-center overflow-hidden"
      bootstrap4
      remote
      keyField="carrierName"
      data={carriers === null ? [] : carriers}
      columns={columns}
    ></BootstrapTable>
  );
};
