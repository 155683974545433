type NonProdEnvs = 'localhost' | '.tst' | '.acc';
type NonProdEnvsList = Array<NonProdEnvs>;

const local: NonProdEnvs[] = ['localhost'];
const development: NonProdEnvs[] = ['localhost', '.tst'];
const acceptance: NonProdEnvs[] = ['localhost', '.tst', '.acc'];

const isAvailableOn = (envs: NonProdEnvsList):boolean => {
  return envs.some((env) =>
    window?.location?.hostname.includes(env)
  )
};

const isAvailableOnLocal = isAvailableOn(local);
const isAvailableOnDevelopment = isAvailableOn(development);
const isAvailableOnAcceptance = isAvailableOn(acceptance);

export {
  isAvailableOnLocal,
  isAvailableOnDevelopment,
  isAvailableOnAcceptance,
}