import { FC, ReactElement, useEffect, useState } from "react";
import { RoutingRulesEdit } from "./routing-rules-edit/RoutingRulesEdit";
import { RoutingRulesEditV2 } from "./routing-rules-edit/RoutingRulesEditV2";
import { TestCases } from "./test-cases-edit/TestCases";
import { TestResults } from "./test-results/TestResults";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../redux/RoutingRulesActions";
import { Alert } from "react-bootstrap";
import { useMsal } from "@azure/msal-react";

export const RoutingRulesPage: FC<{ props: any }> = ({ props }): ReactElement => {
  const dispatch = useDispatch();

  const { currentState } = useSelector(
    (state: any) => ({ currentState: state.routingRules }),
    shallowEqual
  );

  const { accounts }: any = useMsal();
  var roles = accounts[0].idTokenClaims.roles as Array<string>;  
  var filteredRoles = roles.filter(x => x === "Developer");
  var developerMode = filteredRoles.length > 0;
  
  const [routingRulesVersion, setRoutingRulesVersion] = useState(developerMode ? 'v2' : 'v1');

  const toggleVersion = () => {
    setRoutingRulesVersion(routingRulesVersion === 'v1' ? 'v2' : 'v1');
  }

  const { routingRulesYaml, testResult, testSummary, alert, routingRulesV2 } = currentState;

  const handleSaveRules = () => {
    dispatch(actions.saveRules(currentState.routingRulesYaml));
  };

  const handleSaveRulesV2 = () => {
    dispatch(actions.saveRulesV2(currentState.routingRulesV2));
  };

  const handleSaveTestCases = () => {
    dispatch(actions.saveTestCases(currentState.testCases));
  };

  const handleRunTests = () => {
    if (routingRulesVersion === 'v1') {
      dispatch(actions.runTests(currentState.routingRulesYaml, currentState.testCases));
    } else {
      dispatch(actions.runTestsV2(currentState.routingRulesV2, currentState.testCases));
    }
  };

  const handleClearTestResults = () => {
    dispatch(actions.clearTestResults());
  };

  const handleDismissAlert = () => {
    dispatch(actions.dismissAlert());
  };

  useEffect(() => {
    dispatch(actions.clearTestResults());
    dispatch(actions.fetchRoutingRulesV2());
    dispatch(actions.fetchRoutingRules());
    dispatch(actions.fetchTestCases());
  }, [dispatch]);

  return (
    <>
      {alert && (
        <Alert variant={alert.variant} onClose={handleDismissAlert} dismissible>
          <Alert.Heading>{alert.title}</Alert.Heading>
          <p>{alert.message}</p>
          <p>{alert.additionalInfo}</p>
        </Alert>
      )}
      {developerMode &&
        <button onClick={toggleVersion}
          className="btn btn-primary btn-elevate" style={{ float: "right" }}>{routingRulesVersion === 'v1' ? 'View' : 'Code'}</button>
      }
      {routingRulesVersion === 'v1' &&
        <RoutingRulesEdit
          currentRules={routingRulesYaml}
          canSaveRules={currentState.canSaveRoutingRules}
          saveRulesHandler={handleSaveRules}
        ></RoutingRulesEdit>
      }
      {routingRulesVersion === 'v2' &&
        <RoutingRulesEditV2
          currentRules={routingRulesV2}
          canSaveRules={currentState.canSaveRoutingRules}
          saveRulesHandler={handleSaveRulesV2}
        ></RoutingRulesEditV2>
      }
      <br />
      <TestCases
        testCases={currentState.testCases}
        saveTestCases={handleSaveTestCases}
      ></TestCases>
      <br />
      <TestResults
        testResult={testResult}
        testSummary={testSummary}
        runTests={handleRunTests}
        clearTestResults={handleClearTestResults}
      ></TestResults>
    </>
  );
};