// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { shallowEqual, useDispatch } from 'react-redux';
import * as actions from '../redux/ProductsActions';
import {
  // getSelectRow,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
} from '../../../_metronic/_helpers';
import * as uiHelpers from '../ProductsUIHelpers';
// import * as columnFormatters from "./column-formatters";
import { usePlant } from '../../components/PlantHook';
import { useProductsContext } from '../ProductsContext';
import { useAppSelector } from '../../hooks';
import { RootState } from '../../../redux/store';
import { extendEntites, formatLeadTimesCopy } from './utils';
// TODO: Hiding as part of pct-12419 - will add back in next iteration
// import EditIconWithCopy from '../../products/svg/EditIconWithCopy';
// import DeleteIconWithCopy from '../../products/svg/DeleteIconWithCopy';
// import EyeIconIconWithCopy from '../../products/svg/EyeIconWithCopy';
// import ScheduleIconWithCopy from '../../products/svg/ScheduleIconWithCopy';

const ProductsTable = (props: any) => {
  // Products UI Context
  const plant = usePlant();
  const productsContext = useProductsContext();
  const productsUIProps = useMemo(() => {
    return {
      ids: productsContext.ids,
      setIds: productsContext.setIds,
      openEditArticleDialog: productsContext.openEditArticleDialog,
    };
  }, [productsContext]);
  // Getting curret state of products list from store (Redux)
  const currentState = useAppSelector(
    (state: RootState) => state.products,
    shallowEqual
  );
  const { entities } = currentState;
  const extendedEntites = extendEntites(entities);

  // Products Redux state
  const dispatch = useDispatch();
  useEffect(() => {
    // clear selections list
    productsUIProps.setIds([]);
    // server call by queryParams
    dispatch(actions.fetchProducts(productsContext.productGroupName, plant));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    productsContext.productGroupName,
    dispatch,
    plant,
    props.hasLeadTimesUpdated,
  ]);

  // Table columns
  const columns = [
    {
      dataField: 'id',
      text: 'Article Code',
      sort: false,
      formatter: (cell: any, row: any) => {
        return (
          <>
            <div>{row.id}</div>
            <div className='lighter'>{row.desc}</div>
          </>
        );
      },
      // TODO: put back to width-set-15 when ACTIONS column is back
      classes: 'width-set-25',
    },
    {
      dataField: 'leadOptions',
      text: 'Lead Options',
      sort: false,
      formatter: (cell: any, row: any) => {
        return (
          <div className='lighter'>
            <div>Base - {formatLeadTimesCopy(row.leadTime)}</div>
            {/* TODO: Remove extra leadtime when backend work is finished */}
            {row?.extraLeadTime > 0 && (
              <div>
                Base Extra Leadtime - {formatLeadTimesCopy(row.extraLeadTime)}
              </div>
            )}
            <div>
              {row.productOptionLeadTimes?.length
                ? row.productOptionLeadTimes.map(
                    (i: Record<string, string | number>, index: number) => {
                      const optionLeadTimeData = formatLeadTimesCopy(
                        i.leadTime as number
                      );
                      // TODO: Remove extra leadtime when backend work is finished
                      const optionExtraLeadTimeData = formatLeadTimesCopy(
                        i.extraLeadTime as number
                      );
                      const optionsKeyAndValue = `${i.optionKey} : ${i.optionValue}`;
                      const optionsCellLeadtime = `${optionsKeyAndValue} - ${optionLeadTimeData}`;
                      const optionsCellExtraLeadtime = `${optionsKeyAndValue} - Extra Leadtime - ${optionExtraLeadTimeData}`;
                      return (
                        <>
                          <div key={`${optionsKeyAndValue}-${index}`}>{optionsCellLeadtime}</div>
                          {(i?.extraLeadTime as number) > 0 && (
                            <div>{optionsCellExtraLeadtime}</div>
                          )}
                        </>
                      );
                    }
                  )
                : null}
            </div>
          </div>
        );
      },
      classes: 'width-set-35',
    },
    {
      dataField: 'update-this-correct-data-from-contract',
      text: 'Active Schedule',
      sort: false,
      classes: 'width-set-35',
      // TODO: we will need exact data from API and an expand button
      formatter: (cell: any, row: any) => (
        <div className='dateTimeCell'>
          <div>Default</div>
        </div>
      ),
    },
    // TODO: Hiding as part of pct-12419 - will add back in next iteration
    // {
    //   dataField: 'actions',
    //   text: 'Actions',
    //   sort: false,
    //   classes: 'width-set-10',
    //   formatter: (cell: any, row: any) => (
    //     <div className='table-action-icons-container'>
    //       <button
    //         onClick={() => {}}
    //         className='table-action-icons-container-button'
    //       >
    //         <EyeIconIconWithCopy
    //           copy='All options'
    //           customStyle='table-action-icons-children'
    //         />
    //       </button>
    //       <button
    //         onClick={() => {}}
    //         className='table-action-icons-container-button'
    //       >
    //         <ScheduleIconWithCopy
    //           copy='Schedule'
    //           customStyle='table-action-icons-children'
    //           color='#7E8299'
    //         />
    //       </button>
    //       <button
    //         onClick={() => {}}
    //         className='table-action-icons-container-button'
    //       >
    //         <DeleteIconWithCopy
    //           copy='Delete'
    //           customStyle='table-action-icons-children'
    //           color='#7E8299'
    //         />
    //       </button>
    //       <button
    //         onClick={() => {}}
    //         className='table-action-icons-container-button'
    //       >
    //         <EditIconWithCopy
    //           copy='Edit'
    //           customStyle='table-action-icons-children'
    //         />
    //       </button>
    //     </div>
    //   ),
    // },
  ];

  // Table pagination properties
  return (
    <>
      <BootstrapTable
        wrapperClasses='table-responsive'
        bordered={false}
        classes='table table-head-custom table-vertical-center overflow-hidden semi-bold'
        bootstrap4
        remote
        keyField='id'
        data={extendedEntites ?? []}
        columns={columns}
        onTableChange={() => {}}
        defaultSorted={uiHelpers.defaultSorted}
        // TODO: Hiding as part of pct-12419 - will add back in next iteration
        // selectRow={getSelectRow({
        //   entities,
        //   ids: productsUIProps.ids,
        //   setIds: productsUIProps.setIds,
        //   customStyle: true,
        // })}
      >
        <PleaseWaitMessage entities={extendedEntites} />
        <NoRecordsFoundMessage entities={extendedEntites} />
      </BootstrapTable>
    </>
  );
};
export default ProductsTable;