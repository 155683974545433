//Models for Physical products catalog dropdowns
export interface IProductCatalogLookupDto
{
    id: number|null;
    key: string;
    description: string;
}
export enum ProductCatalogLookupType {
    none = 0, 
    productType = 1,
    productCode = 2,
    optionKey = 3,
    optionValue = 4
}
export interface IProductCatalogLookupBulkDto
{
    lookupType: ProductCatalogLookupType,
    parentId: number|null,
    children: IProductCatalogLookupDto[];
}