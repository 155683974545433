// @ts-ignore
import type { OptionGroup } from 'react-querybuilder';

export const channels: OptionGroup[] = [
  {
    brand: 'albelli',
    channels: [
      'albelli.nl',
      'albelli.be'
    ],
  },
  {
    brand: 'bonusprint',
    channels: [
      'bonusprint.co.uk'
    ],
  },
  {
    brand: 'fotoknudsen',
    channels: [
      'fotoknudsen.no'
    ],
  },
  {
    brand: 'hofmann',
    channels: [
      'hofmann.es',
      'hofmann.pt'
    ],
  },
  {
    brand: 'monalbumphoto',
    channels: [
      'monalbumphoto.fr'
    ],
  },
  {
    brand: 'onskefoto',
    channels: [
      'albelli.se'
    ],
  },
  {
    brand: 'photobox',
    channels: [
      'photobox.co.uk',
      'photobox.fr',
      'photobox.dk',
      'photobox.ie',
      'photobox.it',
      'photobox.es',
      'photobox.pt',
    ],
  },
  {
    brand: 'posterXXL',
    channels: [
      'posterxxl.de',
      'posterxxl.at',
      'b2b.posterxxl.de',
      'api.posterxxl.de'
    ],
  },
].map(({ brand, channels }) => ({
  label: brand,
  options: channels.map((s) => ({ name: s, label: s })),
}));