import { Modal } from "react-bootstrap";
import { usePlantRoutingRuleContext } from "../PlantRoutingRuleContext";
import PlantRoutingRuleConditionsDetail from "./PlantRoutingRuleConditionsDetail";
import PlantRoutingRuleFactoryDetail from "./PlantRoutingRuleFactoryDetail";

type Props = {
}

const PlantRoutingRuleDetailModal = (props: Props) => {
    const plantRoutingRulesContext = usePlantRoutingRuleContext();

    const handleOkClick = () => {
        plantRoutingRulesContext.uiEvents.hideRuleDetailsModal()
    }

    return (
        <Modal size="lg" dialogClassName="plant-routing-rule" show={true} aria-labelledby="example-modal-sizes-title-lg">
            <Modal.Header>
                <Modal.Title id="example-modal-sizes-title-lg">
                    Routing Rule Details
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="d-flex">
                    <PlantRoutingRuleConditionsDetail width="width-set-70" condition={plantRoutingRulesContext.selectedRoutingRule?.conditions} />
                    <PlantRoutingRuleFactoryDetail width="width-set-30" decision={plantRoutingRulesContext.selectedRoutingRule?.decision} />
                </div>
                <div className="d-flex justify-content-end">
                    <button
                        onClick={handleOkClick}
                        className='btn btn-outline-theme border-2 fw-bold mr-5 mb-sm-5 mb-md-0'>
                        Edit
                    </button>
                    <button
                        onClick={handleOkClick}
                        className='btn btn-outline-theme border-2 fw-bold mr-5 mb-sm-5 mb-md-0'>
                        Close
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default PlantRoutingRuleDetailModal;