import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { formatCountryName } from "../../helpers/CountryHelper";
import {
  ILocation,
  IShippingLeadTimeCountry,
} from "../../models/CarrierApiModels";
import SVG from "react-inlinesvg";
import { Carrier } from "../../models/CarrierModels";

export const actionsColumnFormatter = (
  cellContent: any,
  carrier: Carrier,
  rowIndex: any,
  { handleEditClick }: { handleEditClick: any }
) => {
  return (
    <button
      title="Edit carrier"
      className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
      onClick={() => handleEditClick(carrier)}
    >
      <span className="svg-icon svg-icon-md svg-icon-primary">
        <SVG
          src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
          title="Edit carrier"
        />
      </span>
    </button>
  );
};

// WIP: example, will be edited later
export const actionsDeleteColumnFormatter = (
  cellContent: any,
  carrier: Carrier,
  rowIndex: any,
  { handleEditClick }: { handleEditClick: any }
) => {
  return (
    <button
      title="Delete packaging group item"
      className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
      onClick={() => handleEditClick(carrier)}
    >
      <span className="svg-icon svg-icon-md svg-icon-primary">
        <SVG
          src={toAbsoluteUrl("/media/svg/icons/Files/Deleted-file.svg")}
          title="Delete packaging group item"
        />
      </span>
    </button>
  );
};

export const countriesColumnFormatter = (
  cellContent: any,
  carrier: Carrier,
  rowIndex: any
) => {
  return (
    <span>
      {carrier.locations
        .map((location: ILocation) => formatCountryName(location))
        .sort((countryA: string, countryB: string) =>
          countryA.localeCompare(countryB)
        ) //Sort alphabetically
        .reduce(
          (
            previous: string,
            current: string,
            index: number,
            vector: string[]
          ) => {
            return previous + " / " + current;
          }
        )}
    </span>
  );
};

export const shippingLeadTimesColumnFormatter = (
  cellContent: any,
  carrier: Carrier,
  rowIndex: any
) => {
  return (
    <span>
      {carrier.locations
        .map((location: ILocation): IShippingLeadTimeCountry => {
          return {
            shippingLeadTime: location.shippingLeadTime,
            countryId: formatCountryName(location),
          };
        })
        .sort(
          (
            locationA: IShippingLeadTimeCountry,
            locationB: IShippingLeadTimeCountry
          ) => locationA.countryId.localeCompare(locationB.countryId)
        ) //Sort alphabetically
        .map((x: IShippingLeadTimeCountry): string => {
          return x.shippingLeadTime.toString();
        })
        .reduce((shippingLeadTimeA: string, shippingLeadTimeB: string) => {
          return shippingLeadTimeA + " / " + shippingLeadTimeB;
        })}
    </span>
  );
};
