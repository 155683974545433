import { createSlice } from "@reduxjs/toolkit";
import { IAlertModel } from "../models/CarrierApiModels";
import { Carrier } from "../models/CarrierModels";

type CarrierState = {
  carriers: Carrier[],
  carrierForEdit?: Carrier,
  showEdit: boolean,
  alert?: IAlertModel,
}

const initialCarriersState : CarrierState = {
  carriers: [],
  carrierForEdit: undefined,
  showEdit: false,
  alert: undefined,
};

export const carrierSlice = createSlice({
  name: "carriers",
  initialState: initialCarriersState,
  reducers: {
    startCall: (state) => {
      state.alert = undefined;
      state.showEdit = false;
    },
    catchError: (state, action) => {
      const { error } = action.payload;
      state.showEdit = false;
      state.alert = error;
    },
    carriersFetched: (state, action) => {
      const { carriersMapped } = action.payload;
      state.alert = undefined;
      state.carriers = carriersMapped;
      state.showEdit = false;
    },
    carrierUpdated: (state, action) => {
      state.alert = action.payload.alert;
      state.showEdit = false;
      state.carriers = state.carriers.map((carrier: Carrier) => {
        if (carrier.carrierName === action.payload.carrier.carrierName) {
          return action.payload.carrier;
        }
        return carrier;
      });
    },
    carrierSelected: (state, action) => {
      const { carrier } = action.payload;
      state.alert = undefined;
      state.carrierForEdit = carrier;
      state.showEdit = true;
    },
    carrierUnselected: (state) => {
      state.alert = undefined;
      state.showEdit = false;
      state.carrierForEdit = undefined;
    },
  },
});
