import { IZipCodeRange } from "../models/CarrierApiModels";

//Formats the list of zipcode ranges as follow:
// 1 zipcode range -> 1000 - 1100
// 2 zipcodes ranges -> 1000 - 1100 / 1200 - 1300
// more than 2 zipcodes ranges -> 1000 - 1100 ... 1400 - 1500
export const getZipCodeRangeFormatted = (
  zipCodeRanges: IZipCodeRange[]
): string => {
  if (zipCodeRanges.length === 1) {
    return `${zipCodeRanges[0].zipCodeStart}-${zipCodeRanges[0].zipCodeEnd}`;
  } else if (zipCodeRanges.length === 2) {
    return (
      zipCodeRanges[0].zipCodeStart +
      "-" +
      zipCodeRanges[0].zipCodeEnd +
      " / " +
      zipCodeRanges[1].zipCodeStart +
      "-" +
      zipCodeRanges[1].zipCodeEnd
    );
  }
  return (
    zipCodeRanges[0].zipCodeStart +
    "-" +
    zipCodeRanges[0].zipCodeEnd +
    "..." +
    zipCodeRanges[zipCodeRanges.length - 1].zipCodeStart +
    "-" +
    zipCodeRanges[zipCodeRanges.length - 1].zipCodeEnd
  );
};
