import { FC, ReactElement, useEffect } from "react";
import { CarrierEditDialog } from "./carrier-edit-dialog/CarrierEditDialog";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../redux/CarrierActions";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import { CarriersTable } from "./carrier-table/CarriersTable";
import { Alert } from "react-bootstrap";
import _ from "lodash";
import { KTUtil } from "../../../_metronic/_assets/js/components/util";
import { Carrier } from "../models/CarrierModels";
import { usePlant, useCarrierConfigurationSupport } from "../../components/PlantHook";

export const CarriersPage: FC<{ props: any }> = ({ props }): ReactElement => {
  const plantCode = usePlant();
  const plantSupportsCarrierConfiguration = useCarrierConfigurationSupport()
  const dispatch = useDispatch();

  const { currentState } = useSelector(
    (state: any) => ({ currentState: state.carriers }),
    shallowEqual
  );

  const { carriers, showEdit, carrierForEdit, alert } = currentState;

  useEffect(() => {
    dispatch(actions.fetchCarriers(plantCode));
  }, [dispatch, plantCode]);

  const handleEditClick = (selectedCarrier: Carrier) => {
    dispatch(actions.selectCarrier(selectedCarrier));
  };

  const save = (carrier: Carrier) => {
    dispatch(actions.updateCarrier(carrier));
    KTUtil.scrollTop(100,500);
  };

  const handleHideDismiss = () => {
    dispatch(actions.unselectCarrier());
  };

  const sortedCarriers = (carriers: Carrier[]) : Carrier[] => {
    return _.orderBy(carriers, [carrier => carrier.dealerIds[0], carrier => carrier.carrierName.toLowerCase()], ['asc', 'asc']);
  }
  if (!plantSupportsCarrierConfiguration){
    return ( <Card {...props}>
      <CardHeader title={"Carrier configuration for " + plantCode + " should be done through Centiro"} {...props}>
        <CardHeaderToolbar></CardHeaderToolbar>
      </CardHeader>
    </Card>)
  }
  return (
    <>
      {alert && (
        <Alert variant={alert.variant} onClose={handleHideDismiss} dismissible>
          <Alert.Heading>{alert.title}</Alert.Heading>
          <p>{alert.message}</p>
        </Alert>
      )}
      <Card {...props}>
        <CardHeader title="Carriers" {...props}>
          <CardHeaderToolbar></CardHeaderToolbar>
        </CardHeader>
        <CardBody {...props}>
          <CarriersTable
            carriers={sortedCarriers(carriers)}
            handleEditClick={handleEditClick}
          ></CarriersTable>
        </CardBody>
      </Card>
      {carrierForEdit && <CarrierEditDialog
        carrierForEdit={carrierForEdit}
        saveCarrier={(values: any) => {
          save(values);
        }}
        showModal={showEdit}
        hideModal={() => {
          handleHideDismiss();
        }}
      ></CarrierEditDialog>}
    </>
  );
};
