import { conditionTypes, ICondition } from "../models/PlantRoutingRuleSetModel"
import BootstrapTable from "react-bootstrap-table-next"

type Props = {
    condition?: ICondition, //with ConditionType === conditionGroup
    width: string
}

type DetailRow = {
    logic: string,
    logicIndentation: number,
    condition: DetailCondition,
    key: string
}

type DetailCondition = {
    variable: string,
    operator: string,
    values: string,
    indentation: number,
}

const getDetailsRecursive = (indentation: number, condition?: ICondition, firstFirstColumn?: string, keyPrefix?: string) => {
    const result = [] as DetailRow[]

    if (!(condition?.conditions) || condition.conditionType !== conditionTypes.conditionGroup || condition.conditions.length === 0) {
        return result;
    }

    condition.conditions.forEach((con, i) => {
        if (con.conditionType === conditionTypes.conditionGroup) {
            const recursionResult = getDetailsRecursive(indentation + 1, con, condition.logicalOperator + ' group', (keyPrefix ?? '') + i + '-')
            result.push(...recursionResult)
        } else if (con.conditionType === conditionTypes.condition) {
            const detailRow = {
                logic: (i === 0) ? (firstFirstColumn ?? '') : (condition.logicalOperator ?? ''),
                logicIndentation: (indentation > 0 && i === 0) ? indentation - 1 : indentation,
                condition: {
                    variable: con.variableName ?? '',
                    operator: con.conditionOperator ?? '',
                    values: con.evaluationValues?.join(', ') ?? '',
                    indentation: indentation
                },
                key: (keyPrefix ?? '') + i
            }
            result.push(detailRow)
        }
    })

    return result
}

const PlantRoutingRuleConditionsDetail = (props: Props) => {
    const conditions = props.condition?.conditions
    const hasConditions = conditions && conditions.length > 0

    const conditionDetails = getDetailsRecursive(0, props.condition, 'where')

    const columns = [
        {
            dataField: 'logic',
            text: 'Trigger',
            sort: false,
            classes: 'width-set-30 py-1',
            formatter: (cell: string, row: DetailRow) => {
                return (
                    <div className={`lighter ml-${row.logicIndentation * 3}`}>{cell}</div>
                )
            }
        },
        {
            dataField: 'condition',
            text: 'condition',
            headerAttrs: {
                hidden: true
            },
            sort: false,
            classes: 'width-set-70 py-1',
            formatter: (cell: DetailCondition) => {
                return (
                    <>
                        <div className={`d-flex ml-${cell.indentation * 3}`}>
                            <div className="text-success mr-2">{cell.variable}</div>
                            <div className="lighter">{cell.operator}</div>
                        </div>
                        <div className={`d-flex ml-${cell.indentation * 3}`}>
                            <div className="mr-2"></div>
                            <div>{cell.values}</div>
                        </div>
                    </>
                )
            }
        }]

    return (
        <>
            {!hasConditions && <div>ALWAYS</div>}
            {hasConditions && <BootstrapTable
                wrapperClasses={`table-responsive ${props.width}`}
                classes='table-borderless'
                bordered={false}
                bootstrap4
                keyField='key'
                data={conditionDetails}
                columns={columns}
            >
            </BootstrapTable>
            }
        </>
    )
}

export default PlantRoutingRuleConditionsDetail