import { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import * as actions from '../redux/PlantRoutingRulesActions'
import { activityRuleTypes, conditionOperators, conditionTypes, IPlantRoutingRule, IPlantRoutingRuleSet, logicalOperators } from "../models/PlantRoutingRuleSetModel";
import BootstrapTable, { SortOrder } from "react-bootstrap-table-next";
import ExpandIcon from "../../products/svg/ExpandIcon";
import moment from "moment";
import EditIconWithCopy from "../../products/svg/EditIconWithCopy";
import CloneIconWithCopy from "../../products/svg/CloneIconWithCopy";
import ResetIconWithCopy from "../../products/svg/ResetIconWithCopy";
import ActiveIconWithCopy from "../../products/svg/ActiveIconWithCopy";
import { usePlantRoutingRuleContext } from "../PlantRoutingRuleContext";

type Props = {
}

const isActive = (row: IPlantRoutingRule) => {
    const unconditionalActivationRule = row.activityRules?.find((rule) => rule.activityRuleType === activityRuleTypes.unconditionalActivationRule)
    return !unconditionalActivationRule || unconditionalActivationRule.active === true
}

const handleActiveToggle = (row: IPlantRoutingRule) => { }

const PlantRoutingRulesTable = (props: Props) => {
    const dispatch = useDispatch();
    const [refreshRoutingRulesRequested, setRefreshRoutingRulesRequested] = useState(false);
    const plantRoutingRulesContext = usePlantRoutingRuleContext();

    const { currentState, alert } = useSelector(
        (state: any) => ({
            currentState: state.plantRoutingRules,
            alert: state.plantRoutingRules?.error || null,
        }),
        shallowEqual
    );

    const handleShowRuleDetails = (row: IPlantRoutingRule) => {
        plantRoutingRulesContext.setSelectedRoutingRule(row);
        plantRoutingRulesContext.uiEvents.showRuleDetailsModal()
    }

    useEffect(() => {
        dispatch(actions.fetchPlantRoutingRules());
    }, [dispatch, refreshRoutingRulesRequested]);

    const plantRoutingRuleSet = currentState.plantRoutingRuleSet as IPlantRoutingRuleSet;

    const columns = [
        {
            dataField: 'sequenceNumber',
            text: 'Priority',
            sort: true,
            //an explicit number sort rule is required, otherwise sorting is alphabetic
            sortFunc: (a: any, b: any, order: SortOrder) => {
                if (order === 'desc') {
                    return Number(b) - Number(a);
                } else {
                    return Number(a) - Number(b);
                }
            },
            classes: 'width-set-10',
        },
        {
            dataField: 'name',
            text: 'Name',
            sort: false,
            classes: 'width-set-25',
        },
        {
            dataField: 'productionSite',
            text: 'Factory',
            sort: false,
            formatter: (cell: any, row: IPlantRoutingRule) => {
                const factory = (row.decision.length === 1) ? row.decision[0].productionSite : 'Multiple'
                const numberFactories = (row.decision.length > 2) ? ` + ${row.decision.length - 2}` : ''
                const factoryList = (row.decision.length > 1) ? `${row.decision[0].productionSite}, ${row.decision[1].productionSite}${numberFactories}` : undefined
                return (
                    <div className="d-flex">
                        <div>
                            <div>{factory}</div>
                            {factoryList && <div className='lighter'>{factoryList}</div>}
                        </div>
                        <div>
                            {factoryList &&
                                <button
                                    className="table-action-icons-container-button ml-3 mt-3"
                                    onClick={() => handleShowRuleDetails(row)}>
                                    <ExpandIcon />
                                </button>}
                        </div>
                    </div>
                );
            },
            classes: 'width-set-10',
        },
        {
            dataField: 'conditions',
            text: 'Conditions',
            sort: false,
            formatter: (cell: any, row: IPlantRoutingRule) => {
                return (
                    <div className="d-flex">
                        <div className="mr-3">{row.conditions.conditions?.length ?? 0} Conditions</div>
                        <button className="table-action-icons-container-button" onClick={() => handleShowRuleDetails(row)}><ExpandIcon /></button>
                    </div>
                );
            },
            classes: 'width-set-15',
        },
        {
            dataField: 'dateTime',
            text: 'Date & Time',
            sort: false,
            formatter: (cell: any, row: IPlantRoutingRule) => {
                const activeBetweenRule = row.activityRules?.find((rule) => rule.activityRuleType === activityRuleTypes.activeBetweenDatesRule)
                if (activeBetweenRule) {
                    const fromMoment = moment(activeBetweenRule.activeFrom);
                    const toMoment = moment(activeBetweenRule.activeUntil);
                    return (
                        <div>
                            <div className="d-flex justify-content-start">
                                <div className="d-flex flex-column mr-2">
                                    <div className="lighter">from:</div>
                                    <div className="lighter">to:</div>
                                </div>
                                <div className="d-flex flex-column">
                                    <div>{fromMoment.format('DD/MM/YY')}</div>
                                    <div>{toMoment.format('DD/MM/YY')}</div>
                                </div>
                                <div className="d-flex flex-column mx-2">
                                    <div className="lighter">at</div>
                                    <div className="lighter">at</div>
                                </div>
                                <div className="d-flex flex-column">
                                    <div>{fromMoment.format('hh:mm UTC')}</div>
                                    <div>{toMoment.format('hh:mm UTC')}</div>
                                </div>
                            </div>
                        </div>
                    )
                } else {
                    return (<div>Default</div>)
                };
            },
            classes: 'width-set-15',
        },
        {
            dataField: 'actions',
            text: 'Actions',
            sort: false,
            formatter: (cell: any, row: IPlantRoutingRule) => (
                <div className='table-action-icons-container-leftaligned'>
                    <button
                        onClick={() => { }}
                        className='table-action-icons-container-button-small'
                    >
                        <EditIconWithCopy
                            copy='Edit'
                            customStyle='table-action-icons-children-small'
                        />
                    </button>
                    <button
                        onClick={() => { }}
                        className='table-action-icons-container-button-small'
                    >
                        <CloneIconWithCopy
                            copy='Clone'
                            customStyle='table-action-icons-children-small'
                        />
                    </button>
                    <button
                        onClick={() => { }}
                        className='table-action-icons-container-button-small'
                    >
                        <ResetIconWithCopy
                            copy='Reset'
                            customStyle='table-action-icons-children-small'
                        />
                    </button>
                    <button
                        onClick={() => { handleActiveToggle(row) }}
                        className='table-action-icons-container-button-small'
                    >
                        <ActiveIconWithCopy
                            active={isActive(row)}
                            copy='Active'
                            customStyle='table-action-icons-children-small'
                        />
                    </button>
                </div>
            ),
            classes: 'width-set-15'
        },
    ];

    return (
        <>
            <BootstrapTable
                wrapperClasses='table-responsive'
                bordered={false}
                classes='table table-head-custom table-vertical-center overflow-hidden semi-bold'
                bootstrap4
                keyField='id'
                data={plantRoutingRuleSet?.decisionRules ?? []}
                columns={columns}
                sort={{ dataField: 'sequenceNumber', order: 'asc' }}
                onTableChange={() => { }}
            >
            </BootstrapTable>
        </>
    );
}

export default PlantRoutingRulesTable;