import { createSlice } from '@reduxjs/toolkit';
import { IPlantRoutingRuleSet } from '../models/PlantRoutingRuleSetModel';

type SliceProps = {
    plantRoutingRuleSet: IPlantRoutingRuleSet | null,
    listLoading: boolean
    lastError: string | null;
    error: string | null;
};

const initialProductsState: SliceProps = {
    plantRoutingRuleSet: null,
    listLoading: false,
    lastError: null,
    error: null
};

export const callTypes = {
    list: 'list'
};

export const plantRoutingRulesSlice = createSlice({
    name: 'plantRoutingRules',
    initialState: initialProductsState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            }
        },
        startCall: (state, action) => {
            state.error = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            }
        },
        plantRoutingRulesFetched: (state, action) => {
            state.listLoading = false;
            state.error = null;
            state.plantRoutingRuleSet = action.payload;
        }
    }
});