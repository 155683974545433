import { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import { Input } from "../../../_metronic/_partials/controls";
import { setPackagingGroupItem, getPackageShapesLookup, getProductShapesLookup } from "../api/PackagingApi";
import MultiSelectDropdown from "../../components/MultiSelectDropdown";
import * as Yup from "yup";
import { getProductCategoriesAsLookup } from "../../products/api/ProductsApi";
import MultiSelectDropdownWithCheckboxes from "../../components/MultiSelectDropdown";
import { IPackagingItem, PackagingItem } from "../models/PackagingGroups";

const defaultPackagingItem = new PackagingItem();

const PackagingGroupEditForm = ({
  onHide,
  onSuccess,
  packagingGroup,
  selectedPackagingItem = defaultPackagingItem,
  plant,
}: any) => {
  const onHideClick = () => {
    onHide();
  };

  const [errorMessage, setErrorMessage] = useState("");

  const validationSchema = Yup.object().shape({
    packagingGroupName: Yup.string().required("Required"),
    shape: Yup.string().required("Required"),
    name: Yup.string().when("packagingItem.name", {
      is: (val: string) => !val || val.trim() === "",
      then: Yup.string().required("Required"),
      otherwise: Yup.string(),
    }),
    innerLength: Yup.number()
      .typeError("Must be a number")
      .required("Required"),
    innerBreadth: Yup.number()
      .typeError("Must be a number")
      .required("Required"),
    innerHeight: Yup.number()
      .typeError("Must be a number")
      .required("Required"),
    innerWeight: Yup.number()
      .typeError("Must be a number")
      .required("Required"),
    outerLength: Yup.number()
      .typeError("Must be a number")
      .required("Required"),
    outerBreadth: Yup.number()
      .typeError("Must be a number")
      .required("Required"),
    outerHeight: Yup.number()
      .typeError("Must be a number")
      .required("Required"),
    outerWeight: Yup.number()
      .typeError("Must be a number")
      .required("Required"),
    minFlexInnerHeight: Yup.number()
      .typeError("Must be a number")
      .optional(),
  });

  const defaultInnerDimension = defaultPackagingItem.innerDimension;
  const defaultOuterDimension = defaultPackagingItem.outerDimension;
  const fitsProductShapesInitialValue = selectedPackagingItem?.fitsProductShapes
    ? selectedPackagingItem.fitsProductShapes.join(", ")
    : defaultPackagingItem.fitsProductShapes.join(", ");
  const categoriesToPackInitialValue = selectedPackagingItem?.categoriesToPack
    ? selectedPackagingItem.categoriesToPack.join(", ")
    : defaultPackagingItem.categoriesToPack;

  const initialValues = {
    packagingGroupName: packagingGroup || "",
    shape: selectedPackagingItem ? selectedPackagingItem.shape || "" : "",
    name: "",
    innerBreadth:
      selectedPackagingItem?.innerDimension?.breadthMm ||
      defaultInnerDimension.breadthMm,
    innerLength:
      selectedPackagingItem?.innerDimension?.lengthMm ||
      defaultInnerDimension.lengthMm,
    innerWeight:
      selectedPackagingItem?.innerDimension?.weightGr ||
      defaultInnerDimension.weightGr,
    innerHeight:
      selectedPackagingItem?.innerDimension?.heightMm ||
      defaultInnerDimension.heightMm,
    outerBreadth:
      selectedPackagingItem?.outerDimension?.breadthMm ||
      defaultOuterDimension.breadthMm,
    outerLength:
      selectedPackagingItem?.outerDimension?.lengthMm ||
      defaultOuterDimension.lengthMm,
    outerWeight:
      selectedPackagingItem?.outerDimension?.weightGr ||
      defaultOuterDimension.weightGr,
    outerHeight:
      selectedPackagingItem?.outerDimension?.heightMm ||
      defaultOuterDimension.heightMm,
    fitsProductShapes: fitsProductShapesInitialValue,
    packagingItem: {
      name: selectedPackagingItem ? selectedPackagingItem.name : "",
    },
    minFlexInnerHeight:
      selectedPackagingItem?.minFlexInnerHeight ||
      defaultPackagingItem.minFlexInnerHeight,

    categoriesToPack: categoriesToPackInitialValue,
  };

  const getPackagingItemToBindTo = (): IPackagingItem => {
    if (selectedPackagingItem !== undefined && selectedPackagingItem !== null) {
     return selectedPackagingItem;
    }
    else {
     return defaultPackagingItem;
    }
 };

  const [selectedFitsProductShapes, setSelectedFitsProductShapes] = useState<Array<{ key: string; description: string }>>(getPackagingItemToBindTo()?.fitsProductShapes?.map((s: string) =>{return {key: s.trim(), description: s.trim()};}));
  const [selectedProductGroupsToPack, setSelectedProductGroupsToPack] = useState<Array<{ key: string; description: string }>>(getPackagingItemToBindTo()?.categoriesToPack?.map((s: string) => {return { key:s.trim(), description: s.trim()};}));
  const [initiallySelectedCategoriesToPack] = useState<Array<{ key: string; description: string }>>(getPackagingItemToBindTo()?.categoriesToPack?.map((s: string) => {return { key:s.trim(), description: s.trim()};}));
  const [productShapes, setProductShapes] = useState<Array<{ key: string; description: string }>>([]);
  const [initiallySelectedFitsProductShapes] = useState<Array<{ key: string; description: string }>>(getPackagingItemToBindTo()?.fitsProductShapes?.map((s: string) => {return { key:s.trim(), description: s.trim()};}));
  const [fitsProductShapes, setFitsProductShapes] = useState<Array<{ key: string; description: string }>>([]);
  const [categoriesToPack, setCategoriesToPack] = useState<Array<{ key: string; description: string }>>([]);
  
  //Fits Product Shapes
  const handleFitsProductShapeDropdownChanges = (selectedFitsProductShapes: Array<{ key: string; description: string }>) => {
    setSelectedFitsProductShapes(selectedFitsProductShapes);
  };

  //Product Groups To Pack
  const handleCategoriesToPackDropdownChanges = (selectedProductGroupsToPack: Array<{ key: string; description: string }>) => {
    setSelectedProductGroupsToPack(selectedProductGroupsToPack);
  };


  useEffect(() => {
    getPackageShapesLookup().then(setProductShapes).catch(error => console.error('Failed to load package shapes:', error));
    getProductShapesLookup().then(fitsProductShapesResponse=> {
      setFitsProductShapes(fitsProductShapesResponse);
      setSelectedFitsProductShapes(initiallySelectedFitsProductShapes);
      handleFitsProductShapeDropdownChanges(selectedFitsProductShapes);
    }).catch(error => console.error('Failed to load fits product shapes:', error));
    getProductCategoriesAsLookup(plant).then(productGroupsResponse=> {
      setCategoriesToPack(productGroupsResponse);
      setSelectedProductGroupsToPack(initiallySelectedCategoriesToPack);
      handleCategoriesToPackDropdownChanges(selectedProductGroupsToPack);
    }).catch(error => console.error('Failed to load Product Groups to Pack:', error));
  }, []);
  
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values, { setStatus }) => {
        try {
          // const fitsProductShapesArray = values.fitsProductShapes
          //   .split(",")
          //   .map((item: string) => item.trim());
          const fitsProductShapesArray = selectedFitsProductShapes.map(option => option.key);
          const categoriesToPackArray = selectedProductGroupsToPack.map(option => option.key);
          const editedPackagingItem = {
            packagingGroupName: values.packagingGroupName,
            packagingItem: {
              name: values.name || values.packagingItem.name,
              shape: values.shape,
              fitsProductShapes: fitsProductShapesArray,
              innerDimension: {
                lengthMm: parseInt(values.innerLength),
                breadthMm: parseInt(values.innerBreadth),
                heightMm: parseInt(values.innerHeight),
                weightGr: parseInt(values.innerWeight),
              },
              outerDimension: {
                lengthMm: parseInt(values.outerLength),
                breadthMm: parseInt(values.outerBreadth),
                heightMm: parseInt(values.outerHeight),
                weightGr: parseInt(values.outerWeight),
              },
              minFlexInnerHeight: parseInt(values.minFlexInnerHeight),
              categoriesToPack: categoriesToPackArray,
            },
          };
          await setPackagingGroupItem(plant, editedPackagingItem);
          setStatus("success");
          setTimeout(() => {
            onHide(); // Close the modal after a successful submission
            onSuccess();
          }, 1500);
        } catch (error: any) {
          setStatus("error");
          setErrorMessage(error.message);
          console.error(
            "Error occured while editing the packaging item",
            error
          );
        }
      }}
    >
      {({ handleSubmit, status }) => (
        <Form>
          <Modal.Body className="overlay overlay-block cursor-default">
            <div className="mb-5">Edit {packagingGroup}: {selectedPackagingItem.name}</div>
            {!selectedPackagingItem.name && (
              <div className="form-group" key="formikFields">
                <Field
                  name="name"
                  component={Input}
                  placeholder="Package Name"
                  label="Package Name"
                />
              </div>
            )}
            <div className="form-group" key="div_shape">
              <label htmlFor="selectPackageShape">Packaging type:</label><br/>
            <Field name="shape" as="select" 
                   placeholder="Envelope, Box, TriangularTube"
                   label="Shape"
                   id="selectPackageShape"
                   >
              <option key="shapeComboNoSelect">(select package shape)</option>
            {productShapes.map((dto: {key: string, description: string}) => (
              <option value={dto.key} key={'shapeCombo_'+dto.key}>{dto.description}</option>
            )
            )}
            </Field>
            </div>
            <div>
              <label htmlFor="FitsProductShapes">Fits product shape(s):</label><br />
              <MultiSelectDropdownWithCheckboxes
              options={fitsProductShapes}
              selectedOptions={selectedFitsProductShapes}
              onSelect={handleFitsProductShapeDropdownChanges} 
              isLocked={false}
              label="Please enter what shapes of the products are suitable for this packaging"
              controlId="FitsProductShapes" />
            </div>
            <div className="form-group row">
              <div className="col-lg-3">
                <Field
                  name="innerLength"
                  component={Input}
                  placeholder="Inner Length (mm)"
                  label="Inner Length (mm)"
                  type="number"
                />
              </div>
              <div className="col-lg-3">
                <Field
                  name="innerBreadth"
                  component={Input}
                  placeholder="Inner Breadth (mm)"
                  label="Inner Width (mm)"
                />
              </div>
              <div className="col-lg-3">
                <Field
                  name="innerHeight"
                  component={Input}
                  placeholder="Inner Height (mm)"
                  label="Inner Height (mm)"
                  type="number"
                />
              </div>
              <div className="col-lg-3">
                <Field
                  name="innerWeight"
                  component={Input}
                  placeholder="Inner Weight (g)"
                  label="Inner Weight (g)"
                  type="number"
                />
              </div>
            </div>
            <div className="form-group row">
              <div className="col-lg-3">
                <Field
                  name="outerLength"
                  component={Input}
                  placeholder="Outer Length (mm)"
                  label="Outer Length (mm)"
                  type="number"
                />
              </div>
              <div className="col-lg-3">
                <Field
                  name="outerBreadth"
                  component={Input}
                  placeholder="Outer Breadth (mm)"
                  label="Outer Width (mm)"
                  type="number"
                />
              </div>
              <div className="col-lg-3">
                <Field
                  name="outerHeight"
                  component={Input}
                  placeholder="Outer Height (mm)"
                  label="Outer Height (mm)"
                  type="number"
                />
              </div>
              <div className="col-lg-3">
                <Field
                  name="outerWeight"
                  component={Input}
                  placeholder="Outer Weight (g)"
                  label="Outer Weight (g)"
                  type="number"
                />
              </div>
            </div>
            <div className="form-group row">
              <div className="col-lg-4">
                <Field
                  name="minFlexInnerHeight"
                  component={Input}
                  placeholder="Min Flex Inner Height"
                  label="Min Flex Inner Height"
                  type="number"
                />
              </div>
            </div>
            <div>
              <MultiSelectDropdown 
              options={categoriesToPack} 
              selectedOptions={selectedProductGroupsToPack}
              onSelect={handleCategoriesToPackDropdownChanges} 
              isLocked={false}
              label="Choose Product Groups to Pack"
              controlId="prodGrps2Pac" />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              onClick={onHideClick}
              className="btn btn-light btn-elevate"
            >
              Cancel
            </button>
            <button
              type="submit"
              onClick={() => handleSubmit()}
              className="btn btn-primary btn-elevate"
            >
              Save
            </button>
          </Modal.Footer>
          {status === "success" && (
            <div className="text-success text-center mt-3 p-10">
              Saved successfully!
            </div>
          )}
          {status === "error" && (
            <div className="text-danger text-center mt-3 p-10">
              An error occurred: {errorMessage}
            </div>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default PackagingGroupEditForm;