import { ICarrierApi } from "../models/CarrierApiModels"
import { Carrier } from "../models/CarrierModels";

export const mapCarrierToICarrierApi = (source: Carrier) : ICarrierApi => {
    // Create a new date based on the number from the input HH:mm
    // Date is not important we create a date after 1970 to avoid parsing issues, month 0 is Jan
    const timeString = source.shippingCutOffTime.split(':');
    const date = new Date(Date.UTC(1970, 0, 1, Number(timeString[0]), Number(timeString[1]), 0));
    const dateString = date.toISOString().replace("1970", "1900"); //Save 1900 to avoid calculation issues.

    return {
        plantCode: source.plantCode,
        carrierName: source.carrierName,
        deliveryType: source.deliveryType,
        dealerIds: source.dealerIds,
        vendorIds: source.vendorIds,
        pickupDays: source.pickupDays,
        deliveryDays: source.deliveryDays,
        shippingCutOffTime: dateString,
        carrierCutoffGroup: source.carrierCutoffGroup,
        locations: source.locations,
        packageConstraints: source.packageConstraints,
        packageLabel: source.packageLabel
    } as ICarrierApi;
}

export const mapICarrierApiToCarrier = (source: ICarrierApi) : Carrier => {
    //Avoid parsing problem, if 1900 is pass as year, it wrongly adds 19 minutes to the time in the browser.
    const dateString = source.shippingCutOffTime.replace('1900', '1970');
    const timeString = new Date(dateString).toLocaleTimeString(navigator.language, {
        hour: '2-digit',
        minute:'2-digit',
        hour12: false,
        timeZone: 'UTC'
    });

    return {
        plantCode: source.plantCode,
        carrierName: source.carrierName,
        deliveryType: source.deliveryType,
        dealerIds: source.dealerIds,
        vendorIds: source.vendorIds,
        pickupDays: source.pickupDays,
        deliveryDays: source.deliveryDays,
        shippingCutOffTime: timeString,
        carrierCutoffGroup: source.carrierCutoffGroup,
        locations: source.locations,
        packageConstraints: source.packageConstraints,
        packageLabel: source.packageLabel
    } as Carrier;
}