import { getAuditLogs } from "../api/AuditLogsApi";
import { IAuditLogList } from "../models/AuditLogModel";
import { useState, useEffect, ChangeEvent } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { sortCaret } from "../../../_metronic/_helpers";
import {
  Form,
  Row,
  Col,
  DropdownButton,
  Dropdown,
  Spinner,
} from "react-bootstrap";
import { usePlant } from "../../components/PlantHook";
import moment, { MomentInput } from "moment";

const AuditLogsPage = () => {
  const [auditLogList, setAuditLogList] = useState({} as IAuditLogList);
  const [resourceType, setResourceType] = useState<string>("");
  const [dataIsLoading, setDataIsLoading] = useState<boolean>(false);
  const [numberOfEntries, setNumberOfEntries] = useState<number>(50);

  const onNumberOfEntriesChanged = (event: ChangeEvent<HTMLInputElement>) => {
    setNumberOfEntries(event.target.valueAsNumber);
    setDataIsLoading(true);
  };

  const resourceTypeSelectionChanged = (eventKey: string | null): void => {
    if (eventKey !== null) {
      setResourceType(eventKey);
      setDataIsLoading(true);
    }
  };

  const dateFormatter = (cell: MomentInput) => {
    const format = "MMMM Do YYYY, H:mm:ss";
    if (!cell) {
      return "";
    }
    return `${
      moment(cell).format(format)
        ? moment(cell).format(format)
        : moment(cell).format(format)
    }`;
  };

  const plantCode = usePlant();

  useEffect(() => {
    getAuditLogs(plantCode, resourceType, numberOfEntries)
      .then((res) => setAuditLogList(res))
      .catch((ex) => {
        console.error(ex);
      })
      .finally(() => setDataIsLoading(false));
  }, [numberOfEntries, resourceType, plantCode]);

  const columnWhiteSpace = {
    whiteSpace: "pre-wrap",
  } as React.CSSProperties;

  const columns = [
    {
      dataField: "timestamp",
      text: "Timestamp",
      sort: true,
      sortCaret: sortCaret,
      formatter: dateFormatter,
    },
    {
      dataField: "resourceType",
      text: "Resource Type",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "username",
      text: "User Name",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "message",
      text: "Message",
      sort: true,
      sortCaret: sortCaret,
      style: columnWhiteSpace,
    },
  ];

  return (
    <>
      <div className="card card-custom card-stretch gutter-b">
        <div className="card-header">
          <h3 className="card-label card-title font-weight-bolder text-dark">
            Filter By
          </h3>
        </div>
        <div className="card-body">
          <Form.Group as={Row} className="align-items-center">
            <Col xs="auto">
              <DropdownButton
                id="select-plant-button"
                title={
                  resourceType === "" ? "Choose a resource type" : resourceType
                }
                onSelect={resourceTypeSelectionChanged}
              >
                <Dropdown.Item eventKey="">All</Dropdown.Item>
                <Dropdown.Item eventKey="Holiday">Holidays</Dropdown.Item>
                <Dropdown.Item eventKey="Carrier">Carriers</Dropdown.Item>
                <Dropdown.Item eventKey="CarrierOverrideRule">
                  CarrierOverrideRule
                </Dropdown.Item>
                <Dropdown.Item eventKey="LeadTime">LeadTimes</Dropdown.Item>                
                <Dropdown.Item eventKey="PackagingGroups">
                  PackagingGroups
                </Dropdown.Item>
                <Dropdown.Item eventKey="PackagingGroupItems">
                  PackagingGroupItems
                </Dropdown.Item>
                <Dropdown.Item eventKey="PackagingProductCategories">
                  PackagingProductCategories
                </Dropdown.Item>
                <Dropdown.Item eventKey="PackagingArticles">
                  PackagingArticles
                </Dropdown.Item>
                <Dropdown.Item eventKey="RoutingRules">RoutingRules</Dropdown.Item>
                <Dropdown.Item eventKey="SkuMappings">SkuMappings</Dropdown.Item>
              </DropdownButton>
            </Col>
            <Col xs="auto">
              <Form.Label htmlFor="numberOfEntriesControl">
                Number of log entries
              </Form.Label>
            </Col>
            <Col xs="auto">
              <Form.Control
                value={numberOfEntries}
                onChange={onNumberOfEntriesChanged}
                type="number"
                id="numberOfEntriesControl"
              />
            </Col>
            {dataIsLoading && (
              <Col>
                <Spinner animation="border" role="status" variant="primary">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              </Col>
            )}
          </Form.Group>
          <div className="separator separator-dashed my-7"></div>
          <BootstrapTable
            wrapperClasses="table-responsive"
            classes="table table-head-custom table-vertical-center overflow-hidden"
            bootstrap4
            bordered={true}
            striped={true}
            remote
            keyField="timestamp"
            data={auditLogList.auditLogs ?? []}
            columns={columns}
            defaultSorted={[{ dataField: "id", order: "asc" }]}
          ></BootstrapTable>
        </div>
      </div>
    </>
  );
};

export { AuditLogsPage };
