import axios from "axios";
import { getConfig } from "../../modules/Config/Config";
import { IPlantRoutingRuleSet } from "../models/PlantRoutingRuleSetModel";

async function getPlantRoutingRules(): Promise<IPlantRoutingRuleSet> {
  const backendConfig = await getConfig("backend");

  return axios
    .get<IPlantRoutingRuleSet>(`${backendConfig.url}/productionsiteroutingrules?ruleSetVersion=0`,
      {
        headers:
        {
          "Content-Type": "application/json",
        }
      })
    .then((response) => response.data);
}

export { getPlantRoutingRules };
