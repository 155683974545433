import { createSlice } from "@reduxjs/toolkit";
import { IAlertModel, RoutingRule } from "../models/RoutingRulesModels";

type RoutingRulesState = {
  routingRulesYaml: string;
  routingRulesV2: RoutingRule[];
  testCases: string;
  testSummary: string;
  testResult: boolean;
  canSaveRoutingRules: boolean;
  alert?: IAlertModel;
};

const initialRoutingRules: RoutingRulesState = {
  routingRulesYaml: "",
  routingRulesV2: [],
  testCases: "",
  testSummary: "",
  testResult: false,
  canSaveRoutingRules: false,
  alert: undefined,
};

export const routingRulesSlice = createSlice({
  name: "routingRules",
  initialState: initialRoutingRules,
  reducers: {
    startCall: (state) => {
      state.canSaveRoutingRules = false;
      state.alert = undefined;
    },
    catchError: (state, action) => {
      const { error } = action.payload;
      state.alert = error;
    },
    routingRulesFetched: (state, action) => {
      const { routingRulesResponse } = action.payload;
      state.alert = undefined;
      state.routingRulesYaml = routingRulesResponse;
    },
    routingRulesV2Fetched: (state, action) => {
      const { routingRulesResponse } = action.payload;
      state.alert = undefined;      
      state.routingRulesV2 = routingRulesResponse.routingRules;
    },
    testCasesFetched: (state, action) => {
      const { testCasesResponse } = action.payload;
      state.alert = undefined;
      state.testCases = testCasesResponse;
    },
    rulesSaved: (state, action) => {
      const { successAlert } = action.payload;
      state.alert = successAlert;
    },
    testCasesSaved: (state, action) => {
      const { successAlert } = action.payload;
      state.alert = successAlert;
    },
    testRunFinished: (state, action) => {
      const { testCasesResults, hasFailedTestCases } = action.payload;
      state.alert = undefined;
      state.testSummary = testCasesResults;
      state.testResult = hasFailedTestCases;
      state.canSaveRoutingRules = !hasFailedTestCases;
    },
    testRunFailed: (state, action) => {
      const { error } = action.payload;
      state.alert = error;
    },
    routingRulesChanged: (state, action) => {
      const { routingRules } = action.payload;
      state.routingRulesYaml = routingRules;
      state.canSaveRoutingRules = false;
    },
    routingRulesV2Changed: (state, action) => {
      const { routingRules } = action.payload;
      state.routingRulesV2 = routingRules;
      state.canSaveRoutingRules = false;
    },
    testCasesChanged: (state, action) => {
      const { testCases } = action.payload;
      state.testCases = testCases;
    },
    testResultsCleared: (state) => {
      state.testSummary = "";
    },
    alertDismissed: (state) => {
      state.alert = undefined;
    },
  },
});
