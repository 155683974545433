import {  FC, ReactElement, useState } from "react";
import { useDispatch } from "react-redux";
import * as actions from "../../redux/RoutingRulesActions";
import { RoutingRuleBuilder, RoutingRule } from "./RoutingRuleBuilder";
import { RoutingRule as RoutingRuleModel } from "../../models/RoutingRulesModels";
import { ProductionSiteSelector } from "./Fields/ProductionSiteSelector";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import DeleteIcon from "../../../products/svg/DeleteIcon";
import DeleteDialog from "../../../components/DeleteDialog";

export const RoutingRulesEditV2: FC<{
  currentRules: RoutingRuleModel[];
  canSaveRules: boolean;
  saveRulesHandler: () => void;
}> = ({ currentRules, canSaveRules, saveRulesHandler }): ReactElement => {
  const dispatch = useDispatch();
  const [currentPlant, setPlant] = useState("All");

  const routingRuleChanged = (routingRule: RoutingRule) => {
    const updatedRules = currentRules.map((r, index) => {
      if ((index + 1).toString() === routingRule.query.id?.toString()) {
        return {
          productionSite: routingRule.plant,
          query: {
            rules: routingRule.query.rules.map((r: any) => ({
              field: r.field,
              operator: r.operator,
              value: r.value
            }))
          }
        };
      }
      return r;
    });
    dispatch(actions.changeRoutingRulesV2(updatedRules));
  }

  const rules = Array<RoutingRule>();

  currentRules?.forEach((x, index) => {
    rules.push({
      plant: x.productionSite,
      query: {
        id: '' + 1 + index, // convert to string for react-query-builder
        rules: x.query.rules.map(r => ({
          field: r.field,
          operator: r.operator,
          value: r.value
        }))
      }
    });
  });

  const filteredRules = rules.filter(x => currentPlant === "All" || x.plant === currentPlant);

  const onPlantSelected = (plant: string) => {
    setPlant(plant);
  }

  const addNewRoutingRuleHandler = () => {
    var updatedList = [...currentRules];
    updatedList.push({
      productionSite: 'YPB',
      query: {
        rules: []
      }
    });
    dispatch(actions.changeRoutingRulesV2(updatedList));
  }

  const handleDrop = (droppedItem: any) => {
    // Ignore drop outside droppable container
    if (!droppedItem.destination) return;

    var updatedList = [...currentRules];
    // Remove dragged item
    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
    // Add dropped item
    updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
    // Update State    
    dispatch(actions.changeRoutingRulesV2(updatedList));
  };

  const [isDeletePopupOpen, setDeletePopupOpen] = useState(false);
  const [selectedRoutingRule, setSelectedRoutingRule] = useState<RoutingRule | null>(null);
  const handleSingleItemDeleteClick = (routingRule: RoutingRule) => {
    setSelectedRoutingRule(routingRule);
    setDeletePopupOpen(true);
  }

  const handleDeleteCancelClick = () => {
    setDeletePopupOpen(false);
    setSelectedRoutingRule(null);
  };

  const handleSingleItemDeleteConfirm = (routingRule: RoutingRule) => {
    try {
      const index = (routingRule.query.id as any) - 1;
      if (index > -1) {
        var updatedList = [...currentRules];
        updatedList.splice(index, 1);
        dispatch(actions.changeRoutingRulesV2(updatedList));
      }
      setDeletePopupOpen(false);
    } catch (error) {
      console.error("Error deleting routing rule:", error);
    }
  };

  return (
    <>
      <div className="container">
        <div className="card-header">
          <div className="row">
            <div className="col-lg-2">
              <h4>Routing Rules V2</h4>
            </div>
            <div className="col-lg-8">
              <ProductionSiteSelector onPlantSelected={onPlantSelected} plant="All" emptySelection={true} />
            </div>
            <div className="col-lg-2">
              <button
                type="submit"
                onClick={saveRulesHandler}
                className="btn btn-primary btn-elevate"
                disabled={!canSaveRules}>
                Save rules
              </button>
            </div>
          </div>

          <DragDropContext onDragEnd={handleDrop}>
            <Droppable droppableId="list-container">
              {(provided) => (
                <div
                  className="list-container"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {filteredRules.map((item, index) => (
                    <Draggable key={`${JSON.stringify(item)}`} draggableId={item.query.id?.toString()} index={index}>
                      {(provided) => (
                        <div
                          className="item-container"
                          ref={provided.innerRef}
                          {...provided.dragHandleProps}
                          {...provided.draggableProps}
                        >

                          <RoutingRuleBuilder routingRule={item} routingRuleChangedHandler={routingRuleChanged} />

                          <button
                            type="button"
                            className="btn btn-link btn-delete-routing-rule"
                            onClick={() => handleSingleItemDeleteClick(item)}
                          >
                            <DeleteIcon />
                          </button>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <button
            type="submit"
            onClick={addNewRoutingRuleHandler}
            className="btn btn-primary btn-elevate btn-add-new-routing-rule">
            Add new rule
          </button>
          <div className="row">
            <div className="col-lg-10">

            </div>
            <div className="col-lg-2">
              <button
                type="submit"
                onClick={saveRulesHandler}
                className="btn btn-primary btn-elevate"
                disabled={!canSaveRules}>
                Save rules
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* Single Item Delete Modal */}
      {selectedRoutingRule && isDeletePopupOpen && (
        <DeleteDialog
          isOpen={isDeletePopupOpen}
          onHide={handleDeleteCancelClick}
          onDelete={() =>
            handleSingleItemDeleteConfirm(selectedRoutingRule)
          }
          title="Delete Routing Rule"
          message={`Are you sure you want to delete this routing rule to '${selectedRoutingRule.plant}'?`}
        />
      )}
    </>
  );
};