import { all } from "redux-saga/effects";
import { combineReducers } from "redux";
import { carrierSlice } from "../app/carrier/redux/CarrierSlice";
import { carrierOverrideSlice } from "../app/carrierOverrides/redux/CarrierOverrideSlice";
import { componentsSlice } from "../app/components/redux/ComponentsSlice";
import { productsSlice } from "../app/leadTimes/redux/ProductsSlice";
import { routingRulesSlice } from "../app/routingRules/redux/RoutingRulesSlice";
import { skuMappingsSlice } from "../app/skuMappings/redux/SkuMappingsSlice";
import { productGroupSlice } from "../app/products/redux/ProductGroupSlice";
import { packagingGroupSlice } from "../app/packaging/redux/PackagingGroupSlice";
import { plantRoutingRulesSlice } from "../app/plantRoutingRules/redux/PlantRoutingRulesSlice";

export const rootReducer = combineReducers({
  products: productsSlice.reducer,
  carriers: carrierSlice.reducer,
  carrierOverrides: carrierOverrideSlice.reducer,
  components: componentsSlice.reducer,
  routingRules: routingRulesSlice.reducer,
  skuMappings: skuMappingsSlice.reducer,
  productGroup: productGroupSlice.reducer,
  packagingGroup: packagingGroupSlice.reducer,
  plantRoutingRules: plantRoutingRulesSlice.reducer
});

export function* rootSaga() {
  yield all([]);
}
