// @ts-ignore
import { ValueEditor, ValueEditorProps } from "react-querybuilder";
import { PercentageSlider } from "./PercentageSlider";
// @ts-ignore
import { FluentValueEditor } from "@react-querybuilder/fluent";

export const ValueEditorSwitcher = (props: ValueEditorProps) => {
    if (props.fieldData.datatype === 'percentage') {
      return (        
          <PercentageSlider {...props} />
      );
    }    
    return <FluentValueEditor {...props} />;
  };