export interface IPlantRoutingRuleSet {
    name: string,
    description: string,
    timestamp: string,
    version: number,
    decisionRules: IPlantRoutingRule[]
}

export interface IPlantRoutingRule {
    id: string,
    name: string,
    description?: string,
    sequenceNumber: number
    activityRules?: IActivityRule[],
    conditions: ICondition,
    decision: IProductionSiteSelectionData[]
}

export interface IActivityRule {
    activityRuleType: string,
    activeFrom?: Date, //activeBetweenDatesRule
    activeUntil?: Date, //activeBetweenDatesRule
    active?: boolean, //unconditionalActivationRule
    activeWeekDays?: string[] //activeOnDayOfWeekRule
}

export const activityRuleTypes = {
    unconditionalActivationRule: 'unconditionalActivationRule',
    activeBetweenDatesRule: 'activeBetweenDatesRule',
    activeOnDayOfWeekRule: 'activeOnDayOfWeekRule'
}

export interface ICondition {
    conditionType: string,
    logicalOperator?: string, //conditionGroup
    conditions?: ICondition[], //conditionGroup 
    variableName?: string, //condition
    conditionOperator?: string, //condition
    evaluationValues?: string[] //condition
}

export const conditionTypes = {
    conditionGroup: 'conditionGroup',
    condition: 'condition'
}

export const logicalOperators = {
    and: 'and',
    or: 'or'
}

export const conditionOperators = {
    equal: 'equal',
    equalOrGreater: 'equalOrGreater',
    equalOrLesser: 'equalOrLesser',
    in: 'in',
    isSubsetOf: 'isSubsetOf',
    isAny: 'isAny'
}

export interface IProductionSiteSelectionData {
    productionSite: string,
    weight: number
}