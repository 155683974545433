import { getPlantRoutingRules } from '../api/PlantRoutingRulesApi';
import { plantRoutingRulesSlice, callTypes } from "./PlantRoutingRulesSlice";

const { actions } = plantRoutingRulesSlice;

export const fetchPlantRoutingRules = () =>
    async (dispatch: (arg0: any) => void) => {
        dispatch(actions.startCall({ callType: callTypes.list }));
        return getPlantRoutingRules()
            .then(response => {
                const plantRoutingRuleSet = response
                dispatch(actions.plantRoutingRulesFetched(plantRoutingRuleSet));
            })
            .catch(error => {
                error.clientMessage = "Can't find Plant Routing Rules";
                dispatch(actions.catchError({ error, callType: callTypes.list }));
            });
    };