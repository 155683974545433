import axios from "axios";
import { getConfig } from "../../modules/Config/Config";
import { ICarrierApi, ICarrierList } from "../models/CarrierApiModels";

async function getCarriers(
  plantCode: string,
  carrierName?: string,
  countryCode?: string
): Promise<ICarrierList> {
  const backendConfig = await getConfig("backend");

  return axios
    .get<ICarrierList>(`${backendConfig.url}/carrier/${plantCode}`, {
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        carrierName: carrierName,
        countryCode: countryCode,
      },
    })
    .then((response) => response.data);
}

async function saveCarriers(carrier: ICarrierApi): Promise<any> {
  const backendConfig = await getConfig("backend");
  
  return axios
    .put(
      `${backendConfig.url}/carrier/${carrier.plantCode}/${carrier.carrierName}`,
      carrier,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
}

export { getCarriers, saveCarriers };
