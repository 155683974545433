import { FC, ReactElement, useState } from "react";
import clsx from "clsx";
import { Dropdown } from "react-bootstrap";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { DropdownTopbarItemToggler } from "../../../../../_metronic/_partials/dropdowns";
import { GetPlants } from "../../../../../app/components/PlantSelectorDropdown";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";

export const ProductionSiteSelector: FC<{ plant: string, onPlantSelected: (plant: string) => void, emptySelection: Boolean }> = ({
  plant, onPlantSelected, emptySelection
}): ReactElement => {

  const emptyElement = {
    plantCode: "All",
    plantSupportsCarrierConfiguration: false,
    flag: toAbsoluteUrl("/media/svg/flags/259-european-union.svg"),
    timeZoneName: "W. Europe Standard Time",
    timeZoneLocation: "Europe/Berlin",
    currentOffset: "02:00:00",
  };
  const allPlants = GetPlants();
  
  if(emptySelection) {
    allPlants.unshift(emptyElement);
  }  

  const [currentPlant, setPlant] = useState(allPlants.find((x) => x.plantCode === plant));

  const onPlantChanged = (plant: any) => {
    setPlant(plant);
    onPlantSelected(plant.name);
  }

  return (
    <Dropdown drop="down" alignRight>
      <Dropdown.Toggle
        as={DropdownTopbarItemToggler}
        id="dropdown-toggle-my-cart"
      >
        <div className="topbar-item">
          <div
            className="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
            id="kt_quick_user_toggle"
          >
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id="language-panel-tooltip">Select Plant</Tooltip>
              }
            >
              <div className="btn btn-icon btn-clean btn-dropdown btn-lg mr-1">
                <span className="symbol symbol-20 mr-3">
                  <img
                    className="h-25px w-25px rounded"
                    src={currentPlant?.flag}
                    alt={currentPlant?.plantCode}
                  />
                </span>
                <span className="navi-text">{currentPlant?.plantCode}</span>
              </div>
            </OverlayTrigger>
          </div>
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround">
        <ul className="navi navi-hover py-4">
          {allPlants.map((p) => (
            <Dropdown.Item
              key={p.plantCode}
              onClick={() => onPlantChanged(p)}
            >
              <li
                key={p.plantCode}
                className={clsx("navi-item d-flex align-items-center", {
                  active: p.plantCode === currentPlant?.plantCode,
                })}
              >
                {p.flag &&
                  <span className="symbol symbol-20 mr-3">
                    <img src={p.flag} alt={p.plantCode} />
                  </span>
                }
                <span className="navi-text">{p.plantCode}</span>
              </li>
            </Dropdown.Item>
          ))}
        </ul>
      </Dropdown.Menu>
    </Dropdown>);
};
