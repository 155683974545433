import { ILocation } from "../models/CarrierApiModels";

export const formatCountryName = (location: ILocation) => {
  return location.countryId === undefined ||
    location.countryId === null ||
    location.countryId === ""
    ? location.inEu
      ? "Europe"
      : "World"
    : location.countryId;
};
