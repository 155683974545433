import BootstrapTable from 'react-bootstrap-table-next'
import { IProductionSiteSelectionData } from '../models/PlantRoutingRuleSetModel'

type Props = {
    decision?: IProductionSiteSelectionData[],
    width: string
}

type FactoryDetail = {
    factory: string,
    percentage: number
}

const PlantRoutingRuleFactoryDetail = (props: Props) => {
    let factoryDetails = [] as FactoryDetail[]
    if (props.decision) {
        const totalWeight = props.decision?.map((site) => site.weight).reduce(function (a, b) { return a + b; }, 0);
        factoryDetails = props.decision?.map((site) => ({
            factory: site.productionSite,
            percentage: Math.round((site.weight / totalWeight) * 100)
        }))
    }

    const columns = [
        {
            dataField: 'factory',
            text: 'Factory',
            sort: false,
            classes: 'width-set-15 py-1',
            formatter: (cell: string, row: FactoryDetail) => {
                return (
                    <div>{`${row.percentage}% of orders: ${row.factory}`}</div>
                )
            }
        }]

    return (
        <BootstrapTable
            wrapperClasses={`table-responsive ${props.width}`}
            classes='table-borderless'
            bordered={false}
            bootstrap4
            keyField='factory'
            data={factoryDetails}
            columns={columns}
        >
        </BootstrapTable>)
}

export default PlantRoutingRuleFactoryDetail