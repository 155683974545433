import { Card, CardBody, CardHeader, CardHeaderToolbar } from '../../../_metronic/_partials/controls';
import PlantRoutingRulesTable from './PlantRoutingRulesTable';

type Props = {
    cardProps?: any;
}

const PlantRoutingRulesCard = (props: Props) => {
    return (
        <Card {...props.cardProps}>
            <CardHeader
                className='border-0'
                title={`Plant Routing Rules`}
                {...props.cardProps}
            >
                <CardHeaderToolbar {...props.cardProps}></CardHeaderToolbar>
            </CardHeader>
            <CardBody {...props.cardProps}>
                <PlantRoutingRulesTable />
            </CardBody>
        </Card>
    );
}

export default PlantRoutingRulesCard;