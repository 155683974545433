import { FC, ReactElement } from "react";
import { Modal } from "react-bootstrap";
import { Carrier } from "../../models/CarrierModels";
import { CarrierEditDialogHeader } from "./CarrierEditDialogHeader";
import { CarrierEditForm } from "./CarrierEditForm";

export const CarrierEditDialog: FC<{
  saveCarrier: (values: Carrier) => void;
  carrierForEdit: Carrier;
  hideModal: () => void;
  showModal: boolean;
}> = ({ saveCarrier, carrierForEdit, hideModal, showModal }): ReactElement => {
  return (
    <>
      <Modal
        size="lg"
        show={showModal}
        onHide={hideModal}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <CarrierEditDialogHeader carrier={carrierForEdit} />
        <CarrierEditForm
          saveCarrier={saveCarrier}
          carrier={carrierForEdit}
          onHide={hideModal}
        />
      </Modal>
    </>
  );
};
