// @ts-ignore
import type { OptionGroup } from 'react-querybuilder';

export const substrateTypes: OptionGroup[] = [
    {
        label: 'Substrate Types',
        types: [         
            'Glossy',
            'Matte',         
            'ExtraWhite',
        ],
    },    
].map(({ label, types }) => ({
  label: label,
  options: types.map((s) => ({ name: s, label: s })),
}));