import { FC, ReactElement, useState, useEffect, useMemo, useRef } from "react";
import { debounce } from "lodash";
// @ts-ignore
import { QueryBuilder, Field, defaultOperators, DefaultOperator, RuleGroupTypeAny } from "react-querybuilder";
import 'react-querybuilder/dist/query-builder.scss';
import { channels } from './Fields/Channels';
import { articleTypes } from './Fields/ArticleTypes';
import { articleCodes } from './Fields/ArticleCodes';
// @ts-ignore
import { QueryBuilderFluent } from '@react-querybuilder/fluent';
import { FluentProvider, webLightTheme } from '@fluentui/react-components';
import { substrateTypes } from "./Fields/SubstrateTypes";
import { ProductionSiteSelector } from "./Fields/ProductionSiteSelector";
import { ValueEditorSwitcher } from "./components/ValueEditorSwitcher";

export type RoutingRule = {
  plant: string;
  query: RuleGroupTypeAny | any;
};

export const RoutingRuleBuilder: FC<{ routingRule: RoutingRule; routingRuleChangedHandler: (routingRule: RoutingRule) => void }> = ({ routingRule, routingRuleChangedHandler }): ReactElement => {

  var initialQuery = {
    id: routingRule.query.id,
    rules: [...routingRule.query.rules]
  };
  routingRule.query.rules.forEach((value: any, index: number) => {
    if (index < routingRule.query.rules.length - 1) {
      initialQuery.rules.splice(2 * index + 1, 0, "and");
    }
  });

  const [query, setQuery] = useState<RuleGroupTypeAny>(initialQuery as RuleGroupTypeAny);
  const [plant, setPlant] = useState<string>(routingRule.plant);


  const isFirstRun = useRef(true);
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
  });

  const onQueryChanged = (q: any) => {    
    setQuery(q);
    if (!isFirstRun.current) {
      debouncedChangeHandler({
        plant: plant,
        query: {
          id: q.id,
          rules: q.rules.filter((x: any) => x !== "and")
        }
      });
    }
  }

  const onPlantSelected = (plant: string) => {
    setPlant(plant);
    routingRuleChangedHandler({
      plant: plant,
      query: query
    })
  }

  const debouncedChangeHandler = useMemo(
    () => debounce(routingRuleChangedHandler, 1000),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // Stop the invocation of the debounced function after unmounting
  useEffect(() => {
    return () => debouncedChangeHandler.cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fields: Field[] = [
    {
      name: 'receiverName',
      label: 'Receiver Name',
      operators: defaultOperators.filter((op: DefaultOperator) => op.name === '=')
    },
    {
      name: 'channelName',
      label: 'Channel',
      valueEditorType: 'multiselect',
      values: channels,
      operators: defaultOperators.filter((op: DefaultOperator) => op.name === 'in')
    },
    {
      name: 'destinationCountry',
      label: 'Country',
      operators: defaultOperators.filter((op: DefaultOperator) => op.name === 'in')
    },
    {
      name: 'articleCode',
      label: 'ArticleCode',
      valueEditorType: 'multiselect',
      values: articleCodes,
      operators: defaultOperators.filter((op: DefaultOperator) => op.name === 'in'),
    },
    {
      name: 'articleType',
      label: 'ArticleType',
      valueEditorType: 'multiselect',
      values: articleTypes,
      operators: defaultOperators.filter((op: DefaultOperator) => op.name === 'in'),
    },
    {
      name: 'premiumLayFlat',
      label: 'PremiumLayFlat',
      valueEditorType: 'checkbox',
      defaultValue: false,
    },
    {
      name: 'substrateType',
      label: 'SubStrateType',
      valueEditorType: 'select',
      values: substrateTypes,
      operators: defaultOperators.filter((op: DefaultOperator) => op.name === 'in'),
    },
    {
      name: 'random',
      label: 'Random',
      operators: defaultOperators.filter((op: DefaultOperator) => op.name === '<=' || op.name === '>='),
      datatype: 'percentage',
      inputType: 'number',
      defaultValue: '.5'
    }
  ];  

  const CombinatorLabel = () => (
    <div></div>
  );

  const AddGroupAction = () => (
    <div></div>
  );

  return (
    <div className="row">
      <div className="col-lg-2 card">
        <label>{query.id}</label>
        <ProductionSiteSelector onPlantSelected={onPlantSelected} plant={routingRule.plant} emptySelection={false} />
      </div>
      <div className="col-lg-10 card">
        <FluentProvider theme={webLightTheme}>
          <QueryBuilderFluent>
            <QueryBuilder
              fields={fields}
              independentCombinators={false}
              showCombinatorsBetweenRules={false}
              combinators={[
                { name: 'and', label: 'AND' }
              ]}
              onQueryChange={onQueryChanged}
              query={query}
              controlElements={{
                combinatorSelector: CombinatorLabel,
                addGroupAction: AddGroupAction,
                valueEditor: ValueEditorSwitcher
              }}
             />
          </QueryBuilderFluent>
        </FluentProvider>
      </div>
    </div>
  );
};