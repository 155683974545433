// @ts-ignore
import type { OptionGroup } from 'react-querybuilder';

export const articleTypes: OptionGroup[] = [
    {
        label: 'Photobooks',
        types: [         
            'HardCoverPhotoBook',
            'HardPhotoCoverPhotoBook',         
            'SoftCoverPhotoBook',
        ],
    },
    {
        label: 'Wall Decor',
        types: [         
            'Aluminium',
            'AluminiumButler',
            'Canvas',
            'Canvas4cm',
            'Forex',
            'Perspex',      
            'Poster',
        ],
    },
    {
        label: 'Mugs',
        types: [         
            'LoveMug',
            'MagicMug',
            'Mug',
        ],
    },
    {
        label: 'other',
        types: [      
            'Calendar',      
            'Cushion',      
            'GreetingCard',      
            'Jigsaw',            
            'Magnet',            
            'Prints',      
            'Voucher',      
        ],  
    },
].map(({ label, types }) => ({
  label: label,
  options: types.map((s) => ({ name: s, label: s })),
}));